import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  Dropdown,
  Accordion,
  FormErrorMessage,
  DeleteConfirmation,
  TextareaWhite,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import AINLPModalLoader from "../AINLPModalLoader";
import { TagWorkflowAction } from "../../../form/actions/tagWorkflowAction";

import {
  createdAIModelTaskSelector,
  createdWorkflowAIModelTaskSelector,
  creatingWorkflowAIModelTaskSelector,
  editedWorkflowAIModelTaskSelector,
  editingWorkflowAIModelTaskSelector,
  fetchedWorkflowAIModelSelector,
  postedWorkflowAIModelSelector,
  postingWorkflowAIModelSelector,
  workflowAIModelSelector,
} from "@store/workflows/selectors";
import {
  clearCreateWorkflowsAIModelTaskAction,
  clearEditWorkflowAIModelsTaskAction,
  createWorkflowsAIModelTaskAction,
  deleteWorkflowsAIModelsTaskAction,
  editWorkflowsAIModelsTaskAction,
  getWorkflowsAIModelsAction,
  setWorkflowFormDirtyAction,
} from "@store/workflows/actions";

import s from "./Step4.module.less";

import { IStepProps } from "../AINLPModal.types";
import { TaskDescriptionMaxLimit } from "../AINLPModal.helpers";
import { IEditWorkflowAIModelTaskPayload, IWorkflowAIModelTask } from "@store/workflows/types";

import {
  TagIcon,
  EditIcon,
  PlusIcon,
  DeleteIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreActionsIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { TaskIcon } from "@assets/index";

const MAX_TASKS_AMOUNT = 5;

const Step4: FC<IStepProps> = ({
  workflowId,
  setCurrentStep,
  previousStep,
  setPreviousStep,
  isEditMode,
  setShowUpdatingStepModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);

  const rightPanelBase = "pages:workflows:workflowCreation:taggingFlow:rightPanel";
  const transBase = `${rightPanelBase}:step4`;

  const [tasks, setTasks] = useState<IWorkflowAIModelTask[]>([]);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [uneditedDescription, setUneditedDescription] = useState<string>("");

  const workflowAIModel = useSelector(workflowAIModelSelector);
  const postedWorkflowAIModel = useSelector(postedWorkflowAIModelSelector);
  const postingWorkflowAIModel = useSelector(postingWorkflowAIModelSelector);
  const creatingWorkflowAIModelTask = useSelector(creatingWorkflowAIModelTaskSelector);
  const creatingWorkflowAIModelTaskDone = useSelector(createdWorkflowAIModelTaskSelector);
  const createdAIModelTask = useSelector(createdAIModelTaskSelector);
  const fetchedWorkflowAIModel = useSelector(fetchedWorkflowAIModelSelector);
  const editingWorkflowAIModelTask = useSelector(editingWorkflowAIModelTaskSelector);
  const editedWorkflowAIModelTask = useSelector(editedWorkflowAIModelTaskSelector);

  const getWorkflowsAIModel = useCallback(
    () => dispatch(getWorkflowsAIModelsAction(workflowId)),
    [dispatch, workflowId],
  );

  const createWorkflowsAIModelTask = useCallback(
    (payload: IEditWorkflowAIModelTaskPayload) =>
      dispatch(createWorkflowsAIModelTaskAction(workflowId, payload)),
    [dispatch, workflowId],
  );

  const editWorkflowsAIModelTask = useCallback(
    (taskId: string, payload: IEditWorkflowAIModelTaskPayload) =>
      dispatch(editWorkflowsAIModelsTaskAction(workflowId, taskId, payload)),
    [dispatch, workflowId],
  );

  const deleteWorkflowsAIModelsTask = useCallback(
    (taskId: string) => dispatch(deleteWorkflowsAIModelsTaskAction(workflowId, taskId)),
    [dispatch, workflowId],
  );

  useEffect(() => {
    if ((previousStep > 3 || isEditMode) && workflowAIModel?.tasks?.length) {
      setTasks(workflowAIModel.tasks);

      const fieldValues = workflowAIModel.tasks.reduce((acc, task, index) => {
        return {
          ...acc,
          ["task-" + index]: task.description,
          ["task-" + index + "-tag"]: [task.tag_option?.type + "::" + task.tag_option?.id],
        };
      }, {});

      form.setFieldsValue(fieldValues);
    }
    // eslint-disable-next-line
  }, [previousStep]);

  useEffect(() => {
    if (editedWorkflowAIModelTask) getWorkflowsAIModel();
    // eslint-disable-next-line
  }, [editedWorkflowAIModelTask]);

  useEffect(() => {
    if (createdAIModelTask && creatingWorkflowAIModelTaskDone) getWorkflowsAIModel();
    // eslint-disable-next-line
  }, [createdAIModelTask, creatingWorkflowAIModelTaskDone]);

  useEffect(() => {
    if ((postedWorkflowAIModel || fetchedWorkflowAIModel) && workflowAIModel?.tasks?.length) {
      const lastTaskBeingCreated = [];

      // if a task was edited while creating a new one, we need to re-add that new one to the tasks list
      if (editedWorkflowAIModelTask && tasks.length && tasks[tasks.length - 1].showCreatingWindow)
        lastTaskBeingCreated.push(tasks[tasks.length - 1]);

      setTasks([...workflowAIModel.tasks, ...lastTaskBeingCreated]);
      workflowAIModel.tasks.forEach((task, _index) => {
        if (task.tag_id)
          form.setFieldValue("task-" + _index + "-tag", [
            task.tag_option?.type + "::" + task.tag_option?.id,
          ]);
      });

      dispatch(clearEditWorkflowAIModelsTaskAction());
      dispatch(clearCreateWorkflowsAIModelTaskAction());
    }
    // eslint-disable-next-line
  }, [postedWorkflowAIModel, fetchedWorkflowAIModel, workflowAIModel, form]);

  const onGoBackClick = () => {
    form.resetFields();
    setPreviousStep(3);
    setCurrentStep(2);
  };

  const closeAllActionDropdowns = () => {
    const tasksCopy = [...tasks];
    tasksCopy.forEach(task => {
      task.openTaskDropdown = false;
    });
    setTasks(tasksCopy);
  };

  const onCollapseTask = (index: number) => {
    const tasksCopy = [...tasks];
    tasksCopy.forEach((task, _index) => {
      if (_index === index) task.collapsed = !task.collapsed;
    });
    setTasks(tasksCopy);

    closeAllActionDropdowns();
  };

  const onAddTaskClick = () => {
    const newTasks = [...tasks];
    newTasks.push({
      description: "",
      collapsed: false,
      showCreatingWindow: true,
      showEditingWindow: false,
      showDeleteConfirmation: false,
    });
    setTasks(newTasks);
  };

  const onDeleteTaskClick = (e: MouseEvent<HTMLSpanElement>, index: number) => {
    e.stopPropagation();
    closeAllActionDropdowns();
    setTasks(
      tasks.map((task, _index) =>
        _index === index ? { ...task, showDeleteConfirmation: true } : task,
      ),
    );
  };

  const onEditTaskClick = (e: MouseEvent<HTMLSpanElement>, index: number) => {
    e.stopPropagation();
    closeAllActionDropdowns();
    setUneditedDescription(tasks[index].description);
    setTasks(
      tasks.map((task, _index) => (_index === index ? { ...task, showEditingWindow: true } : task)),
    );
    tasks.forEach((task, _index) => {
      form.setFieldValue("task-" + _index, task.description);
    });
  };

  const onTaskCreateOrDescriptionEdit = (task: IWorkflowAIModelTask, taskIndex: number) => {
    if (!!task.tag_id && task.id && task.showEditingWindow) {
      editWorkflowsAIModelTask(task.id, {
        description: task.description,
        tag_option: {
          type: task.tag_type || "custom_tag",
          id: task.tag_id || "",
        },
      });
    } else if (!!task.tag_id && task.showCreatingWindow) {
      createWorkflowsAIModelTask({
        description: task.description,
        tag_option: {
          type: task.tag_type || "custom_tag",
          id: task.tag_id || "",
        },
      });
    }

    if (!task.tag_id && task.showEditingWindow) {
      const newTasks = [...tasks];
      newTasks.forEach((_task, _taskIndex) => {
        if (taskIndex === _taskIndex) _task.showEditingWindow = false;
      });
      setTasks(newTasks);
    }
  };

  const onTaskCancel = (task: IWorkflowAIModelTask, taskIndex: number) => {
    if (!task.id) {
      const newTasks = [...tasks];
      newTasks.splice(taskIndex, 1);
      setTasks(newTasks);
      form.setFieldValue("task-" + taskIndex, "");
      form.setFieldValue("task-" + taskIndex + "-tag", []);
    } else {
      const newTasks = [...tasks];
      newTasks.forEach((_task, _taskIndex) => {
        if (taskIndex === _taskIndex) {
          _task.showEditingWindow = false;
          _task.description = uneditedDescription;
          form.setFieldValue("task-" + taskIndex, uneditedDescription);
        }
      });
      setTasks(newTasks);
      setUneditedDescription("");
    }
  };

  const onTaskTagChange = (task: IWorkflowAIModelTask, taskIndex: number) => {
    const tagValues = form.getFieldsValue()["task-" + taskIndex + "-tag"];
    const tagValue = tagValues?.length ? tagValues[0] : "";
    const tagType = tagValue?.startsWith("managed_tag") ? "managed_tag" : "custom_tag";

    if (!!tagValue)
      if (task.id) {
        editWorkflowsAIModelTask(task.id, {
          description: task.description,
          tag_option: {
            type: tagType,
            id: tagValue?.split("::")[1],
          },
        });
      } else {
        const newTasks = [...tasks];
        newTasks.forEach((_task, _taskIndex) => {
          if (taskIndex === _taskIndex && tagValues?.length) {
            _task.tag_id = tagValue?.split("::")[1];
            _task.tag_type = tagType;
          }
        });

        setTasks(newTasks);
      }
  };

  const onDeleteTaskConfirm = (index: number) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
    updatedTasks.forEach((task, _index) => {
      form.setFieldValue("task-" + _index, task.description);
    });
    if (tasks[index]?.id) deleteWorkflowsAIModelsTask(tasks[index].id || "");
  };

  const onDeleteTaskCancel = (index: number) => {
    setTasks(
      tasks.map((task, _index) =>
        _index === index ? { ...task, showDeleteConfirmation: false } : task,
      ),
    );
  };

  const onTaskDescriptionChange = (value: string, taskIndex: number) => {
    const newTasks = [...tasks];
    newTasks[taskIndex].description = value;
    setTasks(newTasks);
    tasks.forEach((_, _index) => {
      if (_index === taskIndex) form.setFieldValue("task-" + _index, value);
    });
  };

  const setOpenTaskDropdown = (open: boolean, index: number, e?: MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    const tasksCopy = [...tasks];
    tasksCopy.forEach((task, _index) => {
      task.openTaskDropdown = index === _index ? open : false;
    });
    setTasks(tasksCopy);
  };

  const onValuesChange = () => {
    const fieldsErrors = form.getFieldsError();
    const mappedErrors = fieldsErrors.reduce(
      (accErrors, error) => ({
        ...accErrors,
        ...(!!error.errors[0] && { [error.name[0]]: error.errors[0] }),
      }),
      {},
    );

    setErrors(mappedErrors);
    dispatch(setWorkflowFormDirtyAction(true));
  };

  const onFinishStep = () => setCurrentStep(4);

  const onFinish = () => {
    if (previousStep > 1) {
      setShowUpdatingStepModal(true, onFinishStep);
    } else onFinishStep();
  };

  const taskRequiredRules = [
    {
      required: true,
      message: <FormErrorMessage>{t(`${transBase}:taskRequiredError`)}</FormErrorMessage>,
    },
    {
      // If contains only whitespaces
      pattern: /^(?!\s*$).+/,
      validateTrigger: "onSubmit",
      message: <FormErrorMessage>{t(`${transBase}:taskRequiredError`)}</FormErrorMessage>,
    },
  ];

  const getTaskActionButtons = (task: IWorkflowAIModelTask, taskIndex: number) => {
    const tagValues = form.getFieldsValue()["task-" + taskIndex + "-tag"];
    const tagValue = tagValues?.length ? tagValues[0] : "";

    return (
      <div className={s.bbTaskEditButtons}>
        <Button
          _size="sm"
          _type="on-surface"
          onClick={() => onTaskCancel(task, taskIndex)}
          className={s.bbTaskCancelButton}
        >
          {t("generic:cancel")}
        </Button>
        <Button
          _size="sm"
          _type="primary"
          loading={creatingWorkflowAIModelTask || editingWorkflowAIModelTask}
          disabled={!task.description || (task.showCreatingWindow ? !tagValue : false)}
          className={s.bbTaskSaveButton}
          onClick={() => onTaskCreateOrDescriptionEdit(task, taskIndex)}
        >
          {task.showCreatingWindow ? t(`${transBase}:addTask`) : t("generic:save")}
        </Button>
      </div>
    );
  };

  return (
    <>
      {postingWorkflowAIModel ? (
        <AINLPModalLoader />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          className={s.bbAIWorkflowStep4}
        >
          <div>
            {tasks.map((task, index) => (
              <Accordion
                key={index}
                type="white"
                collapsed={!!task.collapsed}
                setCollapsed={() => onCollapseTask(index)}
                headerSuffix={
                  task.showCreatingWindow ||
                  task.showDeleteConfirmation ||
                  tasks.some(_task => _task.showEditingWindow) ? undefined : (
                    <Dropdown
                      open={!!task.openTaskDropdown}
                      setOpen={(open: boolean, e?: MouseEvent<HTMLDivElement>) =>
                        setOpenTaskDropdown(open, index, e)
                      }
                      trigger={<MoreActionsIcon />}
                      dropdownClassName={s.bbAIModalDropdown}
                      menuClassName={s.bbAIModalDropdownMenu}
                      placement="bottomRight"
                    >
                      {tasks.every(_task => !_task.showEditingWindow) && (
                        <span
                          className={s.bbAIModalDropdownText}
                          onClick={e => onEditTaskClick(e, index)}
                        >
                          <EditIcon />
                          {t(`${transBase}:editTask`)}
                        </span>
                      )}
                      {index !== 0 && (
                        <span
                          className={s.bbAIModalDropdownText}
                          onClick={e => onDeleteTaskClick(e, index)}
                        >
                          <DeleteIcon />
                          {t(`${transBase}:deleteTask`)}
                        </span>
                      )}
                    </Dropdown>
                  )
                }
                title={
                  <div className={s.bbTaskTitle}>
                    <TaskIcon />
                    {t(`${transBase}:task`) + (index + 1)}
                  </div>
                }
                body={
                  <>
                    {task.showEditingWindow || task.showCreatingWindow ? (
                      <>
                        <Form.Item
                          name={"task-" + index}
                          rules={[
                            {
                              max: TaskDescriptionMaxLimit,
                              message: (
                                <FormErrorMessage>
                                  {t(`${transBase}:taskLengthError`, {
                                    max: TaskDescriptionMaxLimit,
                                  })}
                                </FormErrorMessage>
                              ),
                            },
                            ...(index === 0 ? taskRequiredRules : []),
                          ]}
                        >
                          <TextareaWhite
                            _size="lg"
                            maxLengthChipMinus
                            value={task.description}
                            maxLength={TaskDescriptionMaxLimit}
                            placeholder={t(`${transBase}:taskPlaceholder`)}
                            hasError={errors.hasOwnProperty("task-" + index)}
                            hideMaxLengthChip={task.description.length <= TaskDescriptionMaxLimit}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                              onTaskDescriptionChange(e.target.value, index)
                            }
                            className={s.bbTaskTextareaInner}
                            wrapperClassName={s.bbTaskTextarea}
                          />
                        </Form.Item>
                        {task.showEditingWindow && getTaskActionButtons(task, index)}
                      </>
                    ) : (
                      <div className={s.bbTaskDescription}>{task.description}</div>
                    )}
                    <div className={s.bbTaskTagWrapper}>
                      <div className={s.bbTaskTagIcon}>
                        <TagIcon />
                      </div>
                      <div className={s.bbTaskTagText} ref={ref}>
                        {t(`${transBase}:tagWith`)}
                      </div>
                      <Form.Item
                        name={"task-" + index + "-tag"}
                        key={`tag-action-${task.id || index}`}
                        rules={[
                          {
                            required: true,
                            message: (
                              <FormErrorMessage>{t(`${transBase}:tagRequired`)}</FormErrorMessage>
                            ),
                          },
                        ]}
                        className={s.bbTagTaskActionWrapper}
                      >
                        <TagWorkflowAction
                          id={`tag-action-${task.id || index}`}
                          key={`tag-action-${task.id || index}`}
                          value={task.tag_id ? [task.tag_id] : []}
                          onChange={() => onTaskTagChange(task, index)}
                        />
                      </Form.Item>
                    </div>
                    {task.showCreatingWindow && getTaskActionButtons(task, index)}
                    {task.showDeleteConfirmation && (
                      <DeleteConfirmation
                        message={t(`${transBase}:taskDeleteConfirmation`)}
                        onConfirm={() => onDeleteTaskConfirm(index)}
                        onCancel={() => onDeleteTaskCancel(index)}
                        className={s.bbDeleteConfirmation}
                      />
                    )}
                  </>
                }
                className={s.bbAIWorkflowStep4TaskAccordion}
              />
            ))}
          </div>
          {tasks.length < MAX_TASKS_AMOUNT && tasks.every(task => !task.showCreatingWindow) && (
            <Button _type="on-surface" onClick={onAddTaskClick} className={s.bbAddTaskBtn}>
              <PlusIcon />
              {t(`${transBase}:addTask`)}
            </Button>
          )}
          <Form.Item>
            <div className={s.bbAIWorkflowStep4Footer}>
              <Button
                _type="on-surface"
                onClick={onGoBackClick}
                className={s.bbAIWorkflowStep4BackBtn}
                data-cy="ai-tagging-workflow-step4-backBtn"
              >
                <ChevronLeftIcon />
                {t(`${transBase}:backBtn`)}
              </Button>
              <Button
                type="submit"
                _type="primary"
                className={s.bbAIWorkflowStep4OkBtn}
                data-cy="ai-tagging-workflow-step4-okBtn"
              >
                {t(`${transBase}:okBtn`)}
                <ChevronRightIcon />
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default Step4;
