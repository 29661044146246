import { createReducer, ActionType } from "typesafe-actions";

import {
  createWorkflowAIModelsTaskExamplesSuccessAction,
  createWorkflowsAIModelTaskSuccessAction,
  createWorkflowSuccessAction,
  editWorkflowsAIModelsTaskSuccessAction,
  getMatchingCommentsSuccessAction,
  getWorkflowsAccessUsersSuccessAction,
  getWorkflowsAIModelsSuccessAction,
  getWorkflowsSavedRepliesSuccessAction,
  getWorkflowsSuccessAction,
  postWorkflowsAIModelsSuccessAction,
  testWorkflowAIModelSuccessAction,
} from "./actions";

import { IWorkflowsState } from "./types";
import {
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
  CREATE_WORKFLOW,
  CREATE_WORKFLOW_SUCCESS,
  CREATE_WORKFLOW_FAILURE,
  EDIT_WORKFLOW,
  EDIT_WORKFLOW_SUCCESS,
  EDIT_WORKFLOW_FAILURE,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  ENABLE_WORKFLOW,
  ENABLE_WORKFLOW_SUCCESS,
  ENABLE_WORKFLOW_FAILURE,
  DISABLE_WORKFLOW,
  DISABLE_WORKFLOW_SUCCESS,
  DISABLE_WORKFLOW_FAILURE,
  SET_WORKFLOW_FORM_DIRTY,
  RESET_WORKFLOWS,
  GET_WORKFLOWS_ACCESS_USERS,
  GET_WORKFLOWS_ACCESS_USERS_SUCCESS,
  GET_WORKFLOWS_ACCESS_USERS_FAILURE,
  GET_WORKFLOWS_SAVED_REPLIES,
  GET_WORKFLOWS_SAVED_REPLIES_SUCCESS,
  GET_WORKFLOWS_SAVED_REPLIES_FAILURE,
  GET_MATCHING_COMMENTS,
  GET_MATCHING_COMMENTS_SUCCESS,
  GET_MATCHING_COMMENTS_FAILURE,
  CLEAR_MATCHING_COMMENTS,
  POST_WORKFLOW_AI_MODELS,
  POST_WORKFLOW_AI_MODELS_SUCCESS,
  POST_WORKFLOW_AI_MODELS_FAILURE,
  CLEAR_POST_WORKFLOW_AI_MODELS,
  EDIT_WORKFLOW_AI_MODELS_TASK,
  EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE,
  CREATE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  GET_WORKFLOW_AI_MODELS,
  GET_WORKFLOW_AI_MODELS_SUCCESS,
  GET_WORKFLOW_AI_MODELS_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK,
  DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  TEST_WORKFLOW_AI_MODEL,
  TEST_WORKFLOW_AI_MODEL_SUCCESS,
  TEST_WORKFLOW_AI_MODEL_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK,
  CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK,
} from "./actionTypes";

import { toggleWorkflowEnabled } from "./helpers";

const initialState: IWorkflowsState = {
  workflowItems: [],
  workflowCategories: [],
  workflowRiskOptions: {
    risk_lvl: [],
    impact_area: [],
  },
  workflowsAccessUsers: [],
  workflowsSavedReplies: [],
  workflowAIModel: null,
  createdAIModelTask: null,
  testingWorkflowAIModelResult: null,

  matchingComments: {
    fetching: false,
    fetched: false,
    fetchFail: false,
  },

  formDirty: false,

  initialFetchDone: false,

  fetchingWorkflows: false,
  fetchedWorkflows: false,
  fetchingWorkflowsFailed: false,

  creatingWorkflow: false,
  createdWorkflow: null,
  creatingWorkflowFailed: false,

  editingWorkflow: false,
  editedWorkflow: false,
  editingWorkflowFailed: false,

  deletingWorkflow: false,
  deletedWorkflow: false,
  deletingWorkflowFailed: false,

  enablingWorkflow: false,
  enabledWorkflow: false,
  enablingWorkflowFailed: false,

  disablingWorkflow: false,
  disabledWorkflow: false,
  disablingWorkflowFailed: false,

  fetchingWorkflowsAccessUsers: false,
  fetchedWorkflowsAccessUsers: false,
  fetchingWorkflowsAccessUsersFailed: false,

  fetchingWorkflowsSavedReplies: false,
  fetchedWorkflowsSavedReplies: false,
  fetchingWorkflowsSavedRepliesFailed: false,

  fetchingWorkflowAIModel: false,
  fetchedWorkflowAIModel: false,
  fetchingWorkflowAIModelFailed: false,

  postingWorkflowAIModel: false,
  postedWorkflowAIModel: false,
  postingWorkflowAIModelFailed: false,

  creatingWorkflowAIModelTask: false,
  createdWorkflowAIModelTask: false,
  creatingWorkflowAIModelTaskFailed: false,

  updatingWorkflowAIModelTask: false,
  updatedWorkflowAIModelTask: false,
  updatingWorkflowAIModelTaskFailed: false,

  deletingWorkflowAIModelTask: false,
  deletedWorkflowAIModelTask: false,
  deletingWorkflowAIModelTaskFailed: false,

  creatingWorkflowAIModelTaskExample: false,
  createdWorkflowAIModelTaskExample: null,
  creatingWorkflowAIModelTaskExampleFailed: false,

  updatingWorkflowAIModelTaskExample: false,
  updatedWorkflowAIModelTaskExample: false,
  updatingWorkflowAIModelTaskExampleFailed: false,

  deletingWorkflowAIModelTaskExample: false,
  deletedWorkflowAIModelTaskExample: false,
  deletingWorkflowAIModelTaskExampleFailed: false,

  testingWorkflowAIModel: false,
  testedWorkflowAIModel: false,
  testingWorkflowAIModelFailed: false,
};

export const workflowsReducer = createReducer<IWorkflowsState>(initialState, {
  // RESET_WORKFLOWS
  [RESET_WORKFLOWS]: (state: IWorkflowsState) => ({
    ...initialState,
    initialFetchDone: state.initialFetchDone,
    workflowItems: [...state.workflowItems],
    workflowCategories: [...state.workflowCategories],
    workflowRiskOptions: { ...state.workflowRiskOptions },
  }),
  // GET_WORKFLOWS
  [GET_WORKFLOWS]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflows: true,
    fetchedWorkflows: false,
    fetchingWorkflowsFailed: false,
  }),
  [GET_WORKFLOWS_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getWorkflowsSuccessAction>,
  ) => ({
    ...state,
    workflowItems: action.payload.items,
    workflowCategories: action.payload.categories,
    workflowRiskOptions: action.payload.risk_identifications,
    fetchingWorkflows: false,
    fetchedWorkflows: true,
    fetchingWorkflowsFailed: false,
    initialFetchDone: true,
  }),
  [GET_WORKFLOWS_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflows: false,
    fetchedWorkflows: false,
    fetchingWorkflowsFailed: true,
  }),

  // CREATE_WORKFLOW
  [CREATE_WORKFLOW]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflow: true,
    createdWorkflow: null,
    creatingWorkflowFailed: false,
  }),
  [CREATE_WORKFLOW_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof createWorkflowSuccessAction>,
  ) => ({
    ...state,
    creatingWorkflow: false,
    createdWorkflow: action.payload,
    creatingWorkflowFailed: false,
  }),
  [CREATE_WORKFLOW_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflow: false,
    createdWorkflow: null,
    creatingWorkflowFailed: true,
  }),

  // EDIT_WORKFLOW
  [EDIT_WORKFLOW]: (state: IWorkflowsState) => ({
    ...state,
    editingWorkflow: true,
    editedWorkflow: false,
    editingWorkflowFailed: false,
  }),
  [EDIT_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    editingWorkflow: false,
    editedWorkflow: true,
    editingWorkflowFailed: false,
  }),
  [EDIT_WORKFLOW_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    editingWorkflow: false,
    editedWorkflow: false,
    editingWorkflowFailed: true,
  }),

  // DELETE_WORKFLOW
  [DELETE_WORKFLOW]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflow: true,
    deletedWorkflow: false,
    deletingWorkflowFailed: false,
  }),
  [DELETE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflow: false,
    deletedWorkflow: true,
    deletingWorkflowFailed: false,
  }),
  [DELETE_WORKFLOW_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflow: false,
    deletedWorkflow: false,
    deletingWorkflowFailed: true,
  }),

  // ENABLE_WORKFLOW
  [ENABLE_WORKFLOW]: (state: IWorkflowsState, action) => ({
    ...state,
    enablingWorkflow: true,
    enabledWorkflow: false,
    enablingWorkflowFailed: false,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),
  [ENABLE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    enablingWorkflow: false,
    enabledWorkflow: true,
    enablingWorkflowFailed: false,
  }),
  [ENABLE_WORKFLOW_FAILURE]: (state: IWorkflowsState, action) => ({
    ...state,
    enablingWorkflow: false,
    enabledWorkflow: false,
    enablingWorkflowFailed: true,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),

  // DISABLE_WORKFLOW
  [DISABLE_WORKFLOW]: (state: IWorkflowsState, action) => ({
    ...state,
    disablingWorkflow: true,
    disabledWorkflow: false,
    disablingWorkflowFailed: false,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),
  [DISABLE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    disablingWorkflow: false,
    disabledWorkflow: true,
    disablingWorkflowFailed: false,
  }),
  [DISABLE_WORKFLOW_FAILURE]: (state: IWorkflowsState, action) => ({
    ...state,
    disablingWorkflow: false,
    disabledWorkflow: false,
    disablingWorkflowFailed: true,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),

  // SET_WORKFLOW_FORM_DIRTY
  [SET_WORKFLOW_FORM_DIRTY]: (state, action) => ({
    ...state,
    formDirty: action.payload,
  }),

  // GET_WORKFLOWS_ACCESS_USERS
  [GET_WORKFLOWS_ACCESS_USERS]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowsAccessUsers: true,
    fetchedWorkflowsAccessUsers: false,
    fetchingWorkflowsAccessUsersFailed: false,
  }),
  [GET_WORKFLOWS_ACCESS_USERS_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getWorkflowsAccessUsersSuccessAction>,
  ) => ({
    ...state,
    workflowsAccessUsers: action.payload,
    fetchingWorkflowsAccessUsers: false,
    fetchedWorkflowsAccessUsers: true,
    fetchingWorkflowsAccessUsersFailed: false,
  }),
  [GET_WORKFLOWS_ACCESS_USERS_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowsAccessUsers: false,
    fetchedWorkflowsAccessUsers: false,
    fetchingWorkflowsAccessUsersFailed: true,
  }),

  // GET_WORKFLOWS_SAVED_REPLIES
  [GET_WORKFLOWS_SAVED_REPLIES]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowsSavedReplies: true,
    fetchedWorkflowsSavedReplies: false,
    fetchingWorkflowsSavedRepliesFailed: false,
  }),
  [GET_WORKFLOWS_SAVED_REPLIES_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getWorkflowsSavedRepliesSuccessAction>,
  ) => ({
    ...state,
    workflowsSavedReplies: action.payload,
    fetchingWorkflowsSavedReplies: false,
    fetchedWorkflowsSavedReplies: true,
    fetchingWorkflowsSavedRepliesFailed: false,
  }),
  [GET_WORKFLOWS_SAVED_REPLIES_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowsSavedReplies: false,
    fetchedWorkflowsSavedReplies: false,
    fetchingWorkflowsSavedRepliesFailed: true,
  }),

  // GET_MATCHING_COMMENTS
  [GET_MATCHING_COMMENTS]: (state: IWorkflowsState) => ({
    ...state,
    matchingComments: { fetching: true, fetched: false, fetchFail: false },
  }),
  [GET_MATCHING_COMMENTS_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getMatchingCommentsSuccessAction>,
  ) => ({
    ...state,
    matchingComments: {
      fetching: false,
      fetched: true,
      fetchFail: false,
      data: {
        comments: action.payload.comments,
        totalCount: action.payload.total_count,
      },
    },
  }),
  [GET_MATCHING_COMMENTS_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    matchingComments: { fetching: false, fetched: false, fetchFail: true },
  }),
  [CLEAR_MATCHING_COMMENTS]: (state: IWorkflowsState) => ({
    ...state,
    matchingComments: { fetching: false, fetched: false, fetchFail: false },
  }),

  // GET_WORKFLOW_AI_MODELS
  [GET_WORKFLOW_AI_MODELS]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowAIModel: true,
    fetchedWorkflowAIModel: false,
    fetchingWorkflowAIModelFailed: false,
  }),
  [GET_WORKFLOW_AI_MODELS_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getWorkflowsAIModelsSuccessAction>,
  ) => ({
    ...state,
    workflowAIModel: action.payload,
    fetchingWorkflowAIModel: false,
    fetchedWorkflowAIModel: true,
    fetchingWorkflowAIModelFailed: false,
  }),
  [GET_WORKFLOW_AI_MODELS_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowAIModel: false,
    fetchedWorkflowAIModel: false,
    fetchingWorkflowAIModelFailed: true,
  }),

  // POST_WORKFLOW_AI_MODELS
  [POST_WORKFLOW_AI_MODELS]: (state: IWorkflowsState) => ({
    ...state,
    postingWorkflowAIModel: true,
    postedWorkflowAIModel: false,
    postingWorkflowAIModelFailed: false,
  }),
  [POST_WORKFLOW_AI_MODELS_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof postWorkflowsAIModelsSuccessAction>,
  ) => ({
    ...state,
    workflowAIModel: action.payload,
    postingWorkflowAIModel: false,
    postedWorkflowAIModel: true,
    postingWorkflowAIModelFailed: false,
  }),
  [POST_WORKFLOW_AI_MODELS_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    postingWorkflowAIModel: false,
    postedWorkflowAIModel: false,
    postingWorkflowAIModelFailed: true,
  }),
  [CLEAR_POST_WORKFLOW_AI_MODELS]: (state: IWorkflowsState) => ({
    ...state,
    postingWorkflowAIModel: false,
    postedWorkflowAIModel: false,
    postingWorkflowAIModelFailed: false,
  }),

  // CREATE_WORKFLOW_AI_MODELS_TASK
  [CREATE_WORKFLOW_AI_MODELS_TASK]: (state: IWorkflowsState) => ({
    ...state,
    createdAIModelTask: null,
    creatingWorkflowAIModelTask: true,
    createdWorkflowAIModelTask: false,
    creatingWorkflowAIModelTaskFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof createWorkflowsAIModelTaskSuccessAction>,
  ) => ({
    ...state,
    createdAIModelTask: action.payload,
    creatingWorkflowAIModelTask: false,
    createdWorkflowAIModelTask: true,
    creatingWorkflowAIModelTaskFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    createdAIModelTask: null,
    creatingWorkflowAIModelTask: false,
    createdWorkflowAIModelTask: false,
    creatingWorkflowAIModelTaskFailed: true,
  }),
  [CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK]: (state: IWorkflowsState) => ({
    ...state,
    createdAIModelTask: null,
    creatingWorkflowAIModelTask: false,
    createdWorkflowAIModelTask: false,
    creatingWorkflowAIModelTaskFailed: false,
  }),

  // EDIT_WORKFLOW_AI_MODELS_TASK
  [EDIT_WORKFLOW_AI_MODELS_TASK]: (state: IWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTask: true,
    updatedWorkflowAIModelTask: false,
    updatingWorkflowAIModelTaskFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof editWorkflowsAIModelsTaskSuccessAction>,
  ) => ({
    ...state,
    workflowAIModel: action.payload,
    updatingWorkflowAIModelTask: false,
    updatedWorkflowAIModelTask: true,
    updatingWorkflowAIModelTaskFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTask: false,
    updatedWorkflowAIModelTask: false,
    updatingWorkflowAIModelTaskFailed: true,
  }),
  [CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK]: (state: IWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTask: false,
    updatedWorkflowAIModelTask: false,
    updatingWorkflowAIModelTaskFailed: false,
  }),

  // DELETE_WORKFLOW_AI_MODELS_TASK
  [DELETE_WORKFLOW_AI_MODELS_TASK]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTask: true,
    deletedWorkflowAIModelTask: false,
    deletingWorkflowAIModelTaskFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTask: false,
    deletedWorkflowAIModelTask: true,
    deletingWorkflowAIModelTaskFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTask: false,
    deletedWorkflowAIModelTask: false,
    deletingWorkflowAIModelTaskFailed: true,
  }),

  // CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES
  [CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: true,
    createdWorkflowAIModelTaskExample: null,
    creatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof createWorkflowAIModelsTaskExamplesSuccessAction>,
  ) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: false,
    createdWorkflowAIModelTaskExample: action.payload.id,
    creatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: false,
    createdWorkflowAIModelTaskExample: null,
    creatingWorkflowAIModelTaskExampleFailed: true,
  }),
  [CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflowAIModelTaskExample: false,
    createdWorkflowAIModelTaskExample: null,
    creatingWorkflowAIModelTaskExampleFailed: false,
  }),

  // EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE
  [EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE]: (state: IWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTaskExample: true,
    updatedWorkflowAIModelTaskExample: false,
    updatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTaskExample: false,
    updatedWorkflowAIModelTaskExample: true,
    updatingWorkflowAIModelTaskExampleFailed: false,
  }),
  [EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    updatingWorkflowAIModelTaskExample: false,
    updatedWorkflowAIModelTaskExample: false,
    updatingWorkflowAIModelTaskExampleFailed: true,
  }),

  // DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE
  [DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTaskExample: true,
    deletedWorkflowAIModelTaskExample: false,
    deletingWorkflowAIModelTaskExampleFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTaskExample: false,
    deletedWorkflowAIModelTaskExample: true,
    deletingWorkflowAIModelTaskExampleFailed: false,
  }),
  [DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflowAIModelTaskExample: false,
    deletedWorkflowAIModelTaskExample: false,
    deletingWorkflowAIModelTaskExampleFailed: true,
  }),

  // TEST_WORKFLOW_AI_MODEL
  [TEST_WORKFLOW_AI_MODEL]: (state: IWorkflowsState) => ({
    ...state,
    testingWorkflowAIModel: true,
    testedWorkflowAIModel: false,
    testingWorkflowAIModelFailed: false,
  }),
  [TEST_WORKFLOW_AI_MODEL_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof testWorkflowAIModelSuccessAction>,
  ) => ({
    ...state,
    testingWorkflowAIModel: false,
    testingWorkflowAIModelResult: action.payload.items,
    testedWorkflowAIModel: true,
    testingWorkflowAIModelFailed: false,
  }),
  [TEST_WORKFLOW_AI_MODEL_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    testingWorkflowAIModel: false,
    testedWorkflowAIModel: false,
    testingWorkflowAIModelFailed: true,
  }),
});
