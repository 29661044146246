import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Form } from "antd";
import { Chip, Help, Button, TextareaWhite, FormErrorMessage } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getWorkflowsAction,
  postWorkflowsAIModelsAction,
  setWorkflowFormDirtyAction,
  testWorkflowAIModelAction,
} from "@store/workflows/actions";
import {
  postedWorkflowAIModelSelector,
  postingWorkflowAIModelSelector,
  testedWorkflowAIModelSelector,
  testingWorkflowAIModelResultSelector,
  testingWorkflowAIModelSelector,
} from "@store/workflows/selectors";

import s from "./Step6.module.less";

import { IStepProps, IMessage, ITask } from "../AINLPModal.types";
import { getTagScoreOptions, MessageMaxLimit } from "../AINLPModal.helpers";

import { LoadingOutlined } from "@ant-design/icons";
import { UserIcon, SendIcon, ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { IWorkflowAIModelPayload } from "@store/workflows/types";

const Step6: FC<IStepProps> = ({ workflowId, setCurrentStep, setPreviousStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const rightPanelBase = "pages:workflows:workflowCreation:taggingFlow:rightPanel";
  const transBase = `${rightPanelBase}:step6`;
  const step5TransBase = `${rightPanelBase}:step5`;

  const [messages, setMessages] = useState<IMessage[]>([]);

  const messageValue = Form.useWatch("message", form);

  const testingWorkflowAIModel = useSelector(testingWorkflowAIModelSelector);
  const testedWorkflowAIModel = useSelector(testedWorkflowAIModelSelector);
  const testingWorkflowAIModelResult = useSelector(testingWorkflowAIModelResultSelector);
  const postingWorkflowAIModel = useSelector(postingWorkflowAIModelSelector);
  const postedWorkflowAIModel = useSelector(postedWorkflowAIModelSelector);

  const postWorkflowsAIModels = useCallback(
    (payload: IWorkflowAIModelPayload) => dispatch(postWorkflowsAIModelsAction(payload)),
    [dispatch],
  );

  const getWorkflowsAIModel = useCallback(
    (content: string) => dispatch(testWorkflowAIModelAction(workflowId, content)),
    [dispatch, workflowId],
  );

  useEffect(() => {
    if (testedWorkflowAIModel && testingWorkflowAIModelResult) {
      setMessages([...messages.filter(m => !m.loading), ...testingWorkflowAIModelResult]);
    }
    // eslint-disable-next-line
  }, [testedWorkflowAIModel, testingWorkflowAIModelResult]);

  useEffect(() => {
    if (postedWorkflowAIModel) {
      dispatch(getWorkflowsAction());
      setCurrentStep(6);
    }
    // eslint-disable-next-line
  }, [postedWorkflowAIModel]);

  const onMessageSend = () => {
    const newMessages = [...messages];

    form
      .getFieldsValue()
      ?.message?.split("\n")
      .forEach((messageText: string) => {
        if (messageText)
          newMessages.push({
            text: messageText,
            tasks: [],
            loading: true,
          });
      });

    setMessages(newMessages);
    getWorkflowsAIModel(form.getFieldsValue()?.message);
    form.setFieldsValue({ message: "" });
  };

  const getMessageTasksTooltip = (tasks: ITask[]) => {
    return (
      <div>
        {tasks.map(task => (
          <div key={task.name}>
            <span>{t(`${transBase}:notTaggingWith`)}</span>
            <span>{`'${task.tag}': `}</span>
            <span>{t(`${step5TransBase}:tag:${task.score}-label`)}</span>
          </div>
        ))}
      </div>
    );
  };

  const onGoBackClick = () => {
    form.resetFields();
    setPreviousStep(5);
    setCurrentStep(4);
  };

  const onFinish = () => {
    postWorkflowsAIModels({
      step: "finish",
      workflow_id: workflowId,
    } as IWorkflowAIModelPayload);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      className={s.bbAIWorkflowStep6}
      onValuesChange={() => dispatch(setWorkflowFormDirtyAction(true))}
    >
      <div className={s.bbAIWorkflowStep6Content}>
        <div className={s.bbMessages}>
          {messages.map((message, index) => (
            <div className={s.bbMessageWrapper} key={index}>
              <div className={s.bbUserProfile}>
                <UserIcon />
              </div>
              <div
                className={classNames(s.bbMessage, {
                  [s.bbMessageWhite]:
                    (testingWorkflowAIModel && message.loading) ||
                    (message.tasks.length && message.tasks.every(task => !task.should_tag)),
                })}
              >
                <div className={s.bbMessageText}>{message.text}</div>
                {!!testingWorkflowAIModel && !!message.loading && (
                  <div className={s.bbMessageLoadingWrapper}>
                    <LoadingOutlined spin className={s.bbMessageLoading} />
                    {t(`${transBase}:loading`)}
                  </div>
                )}
                {!!message.tasks.every(task => !task.should_tag) && !message.loading && (
                  <div className={s.bbMessageTask}>
                    <div className={s.bbMessageNoTasks}>{t(`${transBase}:wouldNotBeTagged`)}</div>
                    <div className={s.bbMessageTaskScore}>
                      {t(
                        `${transBase}:` +
                          (message.tasks.length > 1 ? "confidenceLevels" : "confidenceLevel"),
                      )}
                      <span>{message.tasks.map(_task => _task.score).join(", ")}</span>
                      <Help
                        tooltipProps={{
                          title: getMessageTasksTooltip(message.tasks),
                          style: { width: 310 },
                        }}
                      />
                    </div>
                  </div>
                )}
                {message.tasks?.map(task => (
                  <div className={s.bbMessageTask} key={task.name}>
                    {task.should_tag && !!task.tag && (
                      <>
                        <div className={s.bbMessageTaskTag}>
                          {t(`${transBase}:wouldBeTagged`)}
                          <Chip _size="xxs" text={task.tag} className={s.bbTagChip} />
                        </div>
                        <div className={s.bbMessageTaskScore}>
                          {t(`${transBase}:confidenceLevel`)}
                          <span>{getTagScoreOptions(t, true)[task?.score]?.label}</span>
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {!!message.tasks?.length &&
                  !!message.tasks.some(task => !task.should_tag && task.tag) &&
                  !!message.tasks.some(task => task.should_tag || !task.tag) && (
                    <div className={s.bbMessageNoTasks}>
                      {t(`${transBase}:notApplied`)}
                      {message.tasks
                        .filter(task => !task.should_tag)
                        .map(task => (task.tag ? `'${task.tag}'` : ""))
                        .filter(tag => !!tag)
                        .join(", ")}
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
        <Form.Item
          name="message"
          rules={[
            {
              max: MessageMaxLimit,
              message: (
                <FormErrorMessage>
                  {t(`${transBase}:messageLengthError`, {
                    max: MessageMaxLimit,
                  })}
                </FormErrorMessage>
              ),
            },
          ]}
        >
          <TextareaWhite
            _size="lg"
            withEmojiPicker
            maxLength={MessageMaxLimit}
            placeholder={t(`${transBase}:messagePlaceholder`)}
            className={s.bbMessageInput}
            wrapperClassName={s.bbMessageInputWrapper}
            footerSuffix={
              <Button
                _type="primary"
                className={s.bbSendMessageBtn}
                onClick={onMessageSend}
                disabled={
                  messageValue?.length > MessageMaxLimit || !messageValue || testingWorkflowAIModel
                }
              >
                <SendIcon />
              </Button>
            }
          />
        </Form.Item>
        <div className={s.bbMessageInfoText}>{t(`${transBase}:info`)}</div>
      </div>
      <Form.Item>
        <div className={s.bbAIWorkflowStep6Footer}>
          <Button
            _type="on-surface"
            onClick={onGoBackClick}
            disabled={postingWorkflowAIModel}
            className={s.bbAIWorkflowStep6BackBtn}
            data-cy="ai-tagging-workflow-step6-backBtn"
          >
            <ChevronLeftIcon />
            {t(`${transBase}:backBtn`)}
          </Button>
          <Button
            type="submit"
            _type="primary"
            loading={postingWorkflowAIModel}
            className={s.bbAIWorkflowStep6OkBtn}
            data-cy="ai-tagging-workflow-step6-okBtn"
          >
            {t(`${transBase}:okBtn`)}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default Step6;
