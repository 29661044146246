import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useState } from "react";

import {
  Card,
  Chip,
  Button,
  RadioGroup,
  InputWhite,
  FormErrorMessage,
  DeleteConfirmation,
  SimpleSelectSingle,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import AINLPModalLoader from "../AINLPModalLoader";

import {
  getWorkflowsAIModelsAction,
  updateWorkflowAIModelsTaskExamplesAction,
  createWorkflowAIModelsTaskExamplesAction,
  deleteWorkflowAIModelsTaskExamplesAction,
  clearCreateWorkflowAIModelsTaskExamplesAction,
  setWorkflowFormDirtyAction,
} from "@store/workflows/actions";
import {
  createdWorkflowAIModelTaskExampleSelector,
  fetchedWorkflowAIModelSelector,
  fetchingWorkflowAIModelSelector,
  workflowAIModelSelector,
} from "@store/workflows/selectors";

import parentStyles from "../AINLPModal.module.less";
import s from "./Step5.module.less";

import { IStepProps } from "../AINLPModal.types";
import { ExampleMaxLimit, getTagScoreOptions } from "../AINLPModal.helpers";
import {
  ITaskExampleCreationPayload,
  IWorkflowAIModelTask,
  IWorkflowAIModelTaskExample,
} from "@store/workflows/types";

import {
  EditIcon,
  PlusIcon,
  DeleteIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { TaskIcon } from "@assets/index";

const MAX_EXAMPLES_AMOUNT = 7;

const Step5: FC<IStepProps> = ({
  workflowId,
  setCurrentStep,
  previousStep,
  setPreviousStep,
  isEditMode,
  setShowUpdatingStepModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const rightPanelBase = "pages:workflows:workflowCreation:taggingFlow:rightPanel";
  const transBase = `${rightPanelBase}:step5`;

  const [tasks, setTasks] = useState<IWorkflowAIModelTask[]>([]);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [editingExample, setEditingExample] = useState<IWorkflowAIModelTaskExample | null>(null);

  const workflowAIModel = useSelector(workflowAIModelSelector);
  const fetchingWorkflowAIModel = useSelector(fetchingWorkflowAIModelSelector);
  const fetchedWorkflowAIModel = useSelector(fetchedWorkflowAIModelSelector);
  const createdWorkflowAIModelTaskExample = useSelector(createdWorkflowAIModelTaskExampleSelector);

  const getWorkflowsAIModel = useCallback(
    () => dispatch(getWorkflowsAIModelsAction(workflowId)),
    [dispatch, workflowId],
  );

  const createWorkflowAIModelTaskExample = useCallback(
    (taskId: string, payload: ITaskExampleCreationPayload) =>
      dispatch(createWorkflowAIModelsTaskExamplesAction(workflowId, taskId, payload)),
    [dispatch, workflowId],
  );

  const clearCreateWorkflowAIModelsTaskExamples = useCallback(
    () => dispatch(clearCreateWorkflowAIModelsTaskExamplesAction()),
    [dispatch],
  );

  const updateWorkflowAIModelTaskExample = useCallback(
    (taskId: string, exampleId: string, payload: ITaskExampleCreationPayload) =>
      dispatch(updateWorkflowAIModelsTaskExamplesAction(workflowId, taskId, exampleId, payload)),
    [dispatch, workflowId],
  );

  const deleteWorkflowAIModelsTaskExample = useCallback(
    (taskId: string, exampleId: string) =>
      dispatch(deleteWorkflowAIModelsTaskExamplesAction(workflowId, taskId, exampleId)),
    [dispatch, workflowId],
  );

  useEffect(() => {
    if (
      ((previousStep > 4 && fetchedWorkflowAIModel) || isEditMode) &&
      workflowAIModel?.tasks?.length
    ) {
      setTasks(workflowAIModel.tasks);

      const fieldValues = workflowAIModel.tasks.reduce((acc, task, index) => {
        return {
          ...acc,
          ...Object.fromEntries(
            (task.examples || []).flatMap((example, exampleIndex) => [
              ["task-" + index + "-example-" + exampleIndex, example.example],
              [
                "task-" + index + "-example-" + exampleIndex + "-should_tag",
                example.should_tag ? "YES" : "NO",
              ],
              ["task-" + index + "-example-" + exampleIndex + "-score", example.score.toString()],
            ]),
          ),
        };
      }, {});

      form.setFieldsValue(fieldValues);
    }
    // eslint-disable-next-line
  }, [previousStep, fetchedWorkflowAIModel]);

  useEffect(() => {
    getWorkflowsAIModel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (createdWorkflowAIModelTaskExample) {
      setTasks(
        tasks.map(task => ({
          ...task,
          examples: task.examples?.map(example => {
            if (example.manual) {
              return {
                ...example,
                id: createdWorkflowAIModelTaskExample,
              };
            } else return example;
          }),
        })),
      );
    }
    // eslint-disable-next-line
  }, [createdWorkflowAIModelTaskExample]);

  useEffect(() => {
    if (previousStep <= 4 && workflowAIModel && fetchedWorkflowAIModel) {
      setTasks(workflowAIModel?.tasks || []);

      tasks.forEach((task, taskIndex) => {
        task.examples?.forEach((_, exampleIndex) => {
          form.setFieldValue(
            "task-" + taskIndex + "-example-" + exampleIndex + "-should_tag",
            "YES",
          );
        });
      });
    }
    // eslint-disable-next-line
  }, [workflowAIModel, fetchedWorkflowAIModel]);

  const onGoBackClick = () => {
    form.resetFields();
    setPreviousStep(4);
    setCurrentStep(3);
  };

  const onAddExampleClick = (taskIndex: number) => {
    const newTasks = [...tasks];
    tasks.forEach((task, _index) => {
      if (task.examples?.length && taskIndex === _index) {
        task.examples.push({
          example: "",
          should_tag: true,
          score: 4,
          manual: true,
          showAddingConfirmation: true,
          showEditingConfirmation: false,
          showDeleteConfirmation: false,
        });
      }
    });
    setTasks(newTasks);
  };

  const onEditExampleClick = (
    e: MouseEvent<HTMLSpanElement>,
    taskIndex: number,
    exampleIndex: number,
  ) => {
    e.stopPropagation();

    if (tasks[taskIndex].examples?.[exampleIndex]) {
      setEditingExample({
        ...tasks[taskIndex].examples?.[exampleIndex],
        showEditingConfirmation: true,
      } as IWorkflowAIModelTaskExample);

      form.setFieldValue(
        "task-" + taskIndex + "-example-" + exampleIndex,
        tasks[taskIndex].examples?.[exampleIndex].example,
      );
    }

    const newTasks = [...tasks];
    newTasks[taskIndex].examples?.forEach((example, _exampleIndex) => {
      if (exampleIndex === _exampleIndex) {
        example.showEditingConfirmation = true;
      }
    });
    setTasks(newTasks);
  };

  const onExampleChange = (
    mode: "edit" | "add",
    value: string,
    taskIndex: number,
    exampleIndex: number,
  ) => {
    if (mode === "add") {
      const newTasks = [...tasks];
      newTasks[taskIndex].examples?.forEach((example, _exampleIndex) => {
        if (exampleIndex === _exampleIndex) {
          example.example = value;
          form.setFieldValue("task-" + taskIndex + "-example-" + exampleIndex, value);
        }
      });
      setTasks(newTasks);
    } else {
      setEditingExample({ ...editingExample, example: value } as IWorkflowAIModelTaskExample);
      form.setFieldValue("task-" + taskIndex + "-example-" + exampleIndex, value);
    }
  };

  const onExampleShouldTagRadioChange = (
    mode: "edit" | "add",
    value: string,
    taskIndex: number,
    example: IWorkflowAIModelTaskExample,
  ) => {
    const exampleIndex = tasks[taskIndex].examples?.findIndex(e => e.id === example?.id);

    if (mode === "add") {
      const newTasks = [...tasks];
      newTasks[taskIndex].examples?.forEach((_example, _exampleIndex) => {
        if (exampleIndex === _exampleIndex) {
          _example.should_tag = value === "YES";
        }
      });
      setTasks(newTasks);
    } else {
      const taskId = tasks[taskIndex].id;

      setEditingExample({
        ...editingExample,
        should_tag: value === "YES",
      } as IWorkflowAIModelTaskExample);

      if (taskId && example.id)
        updateWorkflowAIModelTaskExample(taskId, example.id, {
          example: example.example,
          score: example.score,
          should_tag: value === "YES",
        });
    }

    form.setFieldValue("task-" + taskIndex + "-example-" + exampleIndex + "-should_tag", value);
  };

  const onCancelExampleClick = (mode: "edit" | "add", taskIndex: number, exampleIndex: number) => {
    if (mode === "add") {
      const newTasks = [...tasks];
      newTasks[taskIndex].examples?.splice(exampleIndex, 1);
      setTasks(newTasks);
      form.setFieldValue("task-" + taskIndex + "-example-" + exampleIndex, "");
    } else {
      setEditingExample(null);
      const newTasks = [...tasks];
      newTasks[taskIndex].examples?.forEach((example, _exampleIndex) => {
        if (exampleIndex === _exampleIndex) {
          example.showEditingConfirmation = false;
          form.setFieldValue(
            "task-" + taskIndex + "-example-" + exampleIndex,
            tasks[taskIndex].examples?.[exampleIndex].example,
          );
        }
      });
      setTasks(newTasks);
    }
  };

  const onScoreChange = (
    mode: "edit" | "add",
    taskIndex: number,
    example: IWorkflowAIModelTaskExample,
    value?: string,
  ) => {
    const exampleIndex = tasks[taskIndex].examples?.findIndex(e => e.id === example?.id);

    if (mode === "add") {
      const newTasks = [...tasks];
      newTasks[taskIndex].examples?.forEach((_example, _exampleIndex) => {
        if (exampleIndex === _exampleIndex) {
          _example.score = parseFloat(value || "0");
        }
      });
      setTasks(newTasks);
    } else {
      const taskId = tasks[taskIndex].id;

      setEditingExample({
        ...editingExample,
        score: parseFloat(value || "0"),
      } as IWorkflowAIModelTaskExample);

      if (taskId && example.id)
        updateWorkflowAIModelTaskExample(taskId, example.id, {
          example: example.example,
          should_tag: example.should_tag,
          score: parseFloat(value || "0"),
        });
    }

    form.setFieldValue("task-" + taskIndex + "-example-" + exampleIndex + "-score", value);
  };

  const onExampleSave = (taskIndex: number, example: IWorkflowAIModelTaskExample) => {
    const taskId = tasks[taskIndex].id;

    if (taskId && example.showAddingConfirmation) {
      createWorkflowAIModelTaskExample(taskId, {
        example: example.example,
        should_tag: example.should_tag,
        score: example.score,
      });
      setTasks(
        tasks.map(task => ({
          ...task,
          examples: task.examples?.map(_example =>
            _example.showAddingConfirmation && example.id === _example.id
              ? {
                  ..._example,
                  manual: true,
                  showAddingConfirmation: false,
                }
              : _example,
          ),
        })),
      );
    } else if (taskId && example.id && editingExample && example.showEditingConfirmation) {
      updateWorkflowAIModelTaskExample(taskId, example.id, {
        example: editingExample?.example,
        should_tag: editingExample?.should_tag,
        score: editingExample?.score,
      });
      setTasks(
        tasks.map(task => ({
          ...task,
          examples: task.examples?.map(_example =>
            _example.showEditingConfirmation && example.id === _example.id
              ? {
                  ..._example,
                  showEditingConfirmation: false,
                }
              : _example,
          ),
        })),
      );
      setEditingExample(null);
    }
  };

  const onDeleteExampleClick = (
    e: MouseEvent<HTMLSpanElement>,
    taskIndex: number,
    exampleIndex: number,
  ) => {
    e.stopPropagation();

    const newTasks = [...tasks];
    newTasks[taskIndex].examples?.forEach((example, _exampleIndex) => {
      if (exampleIndex === _exampleIndex) {
        example.showDeleteConfirmation = true;
      }
    });
    setTasks(newTasks);
  };

  const onDeleteExampleConfirm = (taskIndex: number, exampleIndex: number) => {
    if (tasks[taskIndex].id && tasks[taskIndex].examples?.[exampleIndex].id) {
      deleteWorkflowAIModelsTaskExample(
        tasks[taskIndex].id || "",
        tasks[taskIndex].examples?.[exampleIndex].id || "",
      );

      const tasksCopy = [...tasks];
      tasksCopy[taskIndex].examples?.splice(exampleIndex, 1);
      setTasks(tasksCopy);
    }
  };

  const onDeleteExampleCancel = (taskIndex: number, exampleIndex: number) => {
    const newTasks = [...tasks];
    newTasks[taskIndex].examples?.forEach((example, _exampleIndex) => {
      if (exampleIndex === _exampleIndex) {
        example.showDeleteConfirmation = false;
      }
    });
    setTasks(newTasks);
  };

  const isSubmitButtonDisabled = () => {
    return tasks.some(task =>
      task.examples?.some(
        example => example.showEditingConfirmation || example.showAddingConfirmation,
      ),
    );
  };

  const onValuesChange = () => {
    const fieldsErrors = form.getFieldsError();
    const mappedErrors = fieldsErrors.reduce(
      (accErrors, error) => ({
        ...accErrors,
        ...(!!error.errors[0] && { [error.name[0]]: error.errors[0] }),
      }),
      {},
    );

    setErrors(mappedErrors);
    dispatch(setWorkflowFormDirtyAction(true));
  };

  const onFinishStep = () => {
    clearCreateWorkflowAIModelsTaskExamples();
    setCurrentStep(5);
  };

  const onFinish = () => {
    if (previousStep > 1) {
      setShowUpdatingStepModal(true, onFinishStep);
    } else onFinishStep();
  };

  const exampleRequiredRules = [
    {
      required: true,
      message: <FormErrorMessage>{t(`${transBase}:exampleRequiredError`)}</FormErrorMessage>,
    },
    {
      // If contains only whitespaces
      pattern: /^(?!\s*$).+/,
      validateTrigger: "onSubmit",
      message: <FormErrorMessage>{t(`${transBase}:exampleRequiredError`)}</FormErrorMessage>,
    },
  ];

  return (
    <>
      {fetchingWorkflowAIModel ? (
        <AINLPModalLoader />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          className={s.bbAIWorkflowStep5}
        >
          <div>
            <div className={s.bbAIWorkflowStep5Title}>{t(`${transBase}:title`)}</div>
            {tasks.map((task, taskIndex) => (
              <Card key={task.id || taskIndex} className={s.bbTaskCard}>
                <div className={s.bbTaskTitle}>
                  <TaskIcon />
                  {task.name}
                </div>
                <div className={s.bbTaskDescription}>{t(`${transBase}:subTitle`)}</div>
                {task.examples?.map((example, exampleIndex) => (
                  <div className={s.bbTaskExampleWrapper} key={example.id || exampleIndex}>
                    <div className={s.bbTaskExample}>
                      {example.showEditingConfirmation || example.showAddingConfirmation ? (
                        <>
                          <Form.Item
                            name={"task-" + taskIndex + "-example-" + exampleIndex}
                            rules={[
                              {
                                max: ExampleMaxLimit,
                                message: (
                                  <FormErrorMessage>
                                    {t(`${transBase}:taskLengthError`, {
                                      max: ExampleMaxLimit,
                                    })}
                                  </FormErrorMessage>
                                ),
                              },
                              ...(taskIndex === 0 ? exampleRequiredRules : []),
                            ]}
                          >
                            <InputWhite
                              _size="md"
                              hideMaxLengthChip
                              value={
                                example.showEditingConfirmation
                                  ? editingExample?.example
                                  : example.example
                              }
                              maxLength={ExampleMaxLimit}
                              hasError={errors.hasOwnProperty(
                                "task-" + taskIndex + "-example-" + exampleIndex,
                              )}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onExampleChange(
                                  example.showEditingConfirmation ? "edit" : "add",
                                  e.target.value,
                                  taskIndex,
                                  exampleIndex,
                                )
                              }
                              className={s.bbExampleInput}
                              wrapperClassName={classNames(s.bbExampleInputWrapper, {
                                [s.bbExampleInputWrapperError]: errors.hasOwnProperty(
                                  "task-" + taskIndex + "-example-" + exampleIndex,
                                ),
                              })}
                            />
                          </Form.Item>
                          <div className={s.bbExampleEditButtons}>
                            <Button
                              _size="sm"
                              _type="on-surface"
                              onClick={() =>
                                onCancelExampleClick(
                                  example.showEditingConfirmation ? "edit" : "add",
                                  taskIndex,
                                  exampleIndex,
                                )
                              }
                              className={s.bbExampleCancelButton}
                            >
                              {t("generic:cancel")}
                            </Button>
                            <Button
                              _size="sm"
                              _type="primary"
                              disabled={
                                example.showEditingConfirmation
                                  ? !editingExample?.example
                                  : !example.example
                              }
                              className={s.bbExampleSaveButton}
                              onClick={() => onExampleSave(taskIndex, example)}
                            >
                              {example.showEditingConfirmation
                                ? t("generic:save")
                                : t("generic:add")}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className={s.bbTaskExampleText}>
                          <span>{example.example}</span>
                          <div className={s.bbExampleActionBtns}>
                            <Button
                              _type="custom"
                              onClick={e => onEditExampleClick(e, taskIndex, exampleIndex)}
                              className={s.bbEditExampleBtn}
                            >
                              <EditIcon />
                            </Button>
                            {!!example.manual && (
                              <Button
                                _type="custom"
                                onClick={e => onDeleteExampleClick(e, taskIndex, exampleIndex)}
                                className={s.bbDeleteExampleBtn}
                              >
                                <DeleteIcon />
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={s.bbTagWrapper}>
                      <Form.Item
                        name={"task-" + taskIndex + "-example-" + exampleIndex + "-should_tag"}
                        className={s.bbTagWrapperForm}
                        initialValue={
                          (
                            example.showEditingConfirmation
                              ? editingExample?.should_tag
                              : example.should_tag
                          )
                            ? "YES"
                            : "NO"
                        }
                      >
                        <RadioGroup
                          value={
                            (
                              example.showEditingConfirmation
                                ? editingExample?.should_tag
                                : example.should_tag
                            )
                              ? "YES"
                              : "NO"
                          }
                          className={s.bbExampleShouldTagRadio}
                          onChange={(value: string) =>
                            onExampleShouldTagRadioChange(
                              example.showAddingConfirmation ? "add" : "edit",
                              value,
                              taskIndex,
                              example,
                            )
                          }
                        >
                          <RadioGroup.Button _size="md" value={"YES"}>
                            {t(`${transBase}:taskShouldTag`)}
                            <Chip
                              _size="xxs"
                              _type="primary"
                              className={s.bbExampleScoreChip}
                              text={task.name}
                            />
                          </RadioGroup.Button>
                          <RadioGroup.Button _size="md" value={"NO"}>
                            {t(`${transBase}:taskShouldntTag`)}
                          </RadioGroup.Button>
                        </RadioGroup>
                      </Form.Item>
                      <Form.Item
                        name={"task-" + taskIndex + "-example-" + exampleIndex + "-score"}
                        className={s.bbTagWrapperForm}
                        initialValue={
                          example.showEditingConfirmation
                            ? editingExample?.score?.toString()
                            : example.score?.toString()
                        }
                      >
                        <SimpleSelectSingle
                          options={getTagScoreOptions(
                            t,
                            example.showEditingConfirmation
                              ? !!editingExample?.should_tag
                              : !!example.should_tag,
                          )}
                          value={
                            example.showEditingConfirmation
                              ? editingExample?.score?.toString()
                              : example.score?.toString()
                          }
                          onChange={value =>
                            onScoreChange(
                              example.showAddingConfirmation ? "add" : "edit",
                              taskIndex,
                              example,
                              value as string,
                            )
                          }
                          className={s.bbScoreSelect}
                          minWidth={440}
                          dropdownHeaderPrefix={
                            <div className={s.bbScoreSelectHeader}>
                              {t(`${transBase}:tag:header`)}
                            </div>
                          }
                        />
                      </Form.Item>
                    </div>
                    {example.showDeleteConfirmation && (
                      <DeleteConfirmation
                        message={t(`${transBase}:exampleDeleteConfirmation`)}
                        onConfirm={() => onDeleteExampleConfirm(taskIndex, exampleIndex)}
                        onCancel={() => onDeleteExampleCancel(taskIndex, exampleIndex)}
                        className={s.bbDeleteConfirmation}
                      />
                    )}
                    {exampleIndex !== (task.examples?.length || 0) - 1 && (
                      <div className={parentStyles.bbDivider}></div>
                    )}
                  </div>
                ))}
                {!!task.examples?.length &&
                  task.examples.length < MAX_EXAMPLES_AMOUNT &&
                  task.examples.every(example => !example.showAddingConfirmation) && (
                    <Button
                      _type="on-surface"
                      onClick={() => onAddExampleClick(taskIndex)}
                      className={s.bbAddExampleBtn}
                    >
                      <PlusIcon />
                      {t(`${transBase}:addExample`)}
                    </Button>
                  )}
              </Card>
            ))}
          </div>
          <Form.Item>
            <div className={s.bbAIWorkflowStep5Footer}>
              <Button
                _type="on-surface"
                onClick={onGoBackClick}
                disabled={isSubmitButtonDisabled()}
                className={s.bbAIWorkflowStep5BackBtn}
                data-cy="ai-tagging-workflow-step5-backBtn"
              >
                <ChevronLeftIcon />
                {t(`${transBase}:backBtn`)}
              </Button>
              <Button
                type="submit"
                _type="primary"
                disabled={isSubmitButtonDisabled()}
                className={s.bbAIWorkflowStep5OkBtn}
                data-cy="ai-tagging-workflow-step5-okBtn"
              >
                {t(`${transBase}:okBtn`)}
                <ChevronRightIcon />
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default Step5;
