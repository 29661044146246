import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useState } from "react";

import {
  Button,
  Input,
  Label,
  Dropdown,
  Accordion,
  TextareaWhite,
  FormErrorMessage,
  DeleteConfirmation,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import AINLPModalLoader from "../AINLPModalLoader";

import {
  postedWorkflowAIModelSelector,
  postingWorkflowAIModelSelector,
  workflowAIModelSelector,
} from "@store/workflows/selectors";
import { postWorkflowsAIModelsAction, setWorkflowFormDirtyAction } from "@store/workflows/actions";

import parentStyles from "../AINLPModal.module.less";
import s from "./Step3.module.less";

import {
  CategoryDescriptionMaxLimit,
  CategoryDescriptionMinLimit,
  ExampleDescriptionMaxLimit,
  ExclusionsDescriptionMaxLimit,
} from "../AINLPModal.helpers";
import { IStepProps, ICategory } from "../AINLPModal.types";
import { IMinLengthChipProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IWorkflowAIModelCategoryError, IWorkflowAIModelPayload } from "@store/workflows/types";

import {
  PlusIcon,
  CheckIcon,
  DeleteIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MoreActionsIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const MAX_CATEGORIES_AMOUNT = 5;

const Step3: FC<IStepProps> = ({
  workflowId,
  setCurrentStep,
  previousStep,
  setPreviousStep,
  isEditMode,
  setShowUpdatingStepModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const rightPanelBase = "pages:workflows:workflowCreation:taggingFlow:rightPanel";
  const transBase = `${rightPanelBase}:step3`;

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [openDeleteCategoryDropdown, setOpenDeleteCategoryDropdown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [categoryAPIErrors, setCategoryAPIErrors] = useState<
    Record<string, IWorkflowAIModelCategoryError>
  >({});

  const workflowAIModel = useSelector(workflowAIModelSelector);
  const postingWorkflowAIModel = useSelector(postingWorkflowAIModelSelector);
  const postedWorkflowAIModel = useSelector(postedWorkflowAIModelSelector);

  const postWorkflowsAIModels = useCallback(
    (payload: IWorkflowAIModelPayload) => dispatch(postWorkflowsAIModelsAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    if ((previousStep > 2 || isEditMode) && workflowAIModel?.category?.items.length) {
      const _categories = workflowAIModel.category.items.map(category => ({
        ...category,
        examples: category.examples.map(example => example || ""),
        collapsed: false,
        showDeleteConfirmation: false,
      }));
      setCategories(_categories);

      const fieldValues = _categories.reduce((acc, category, index) => {
        return {
          ...acc,
          ["category-" + index]: category.description,
          ["category-" + index + "-exclusions"]: category.exclusion,
          ...Object.fromEntries(
            category.examples.map((example, exampleIndex) => [
              "category-" + index + "-example-" + exampleIndex,
              example,
            ]),
          ),
        };
      }, {});

      form.setFieldsValue(fieldValues);
    }
    // eslint-disable-next-line
  }, [previousStep]);

  useEffect(() => {
    if (previousStep < 3 && !isEditMode)
      setCategories([
        { description: "", collapsed: false, showDeleteConfirmation: false, examples: [""] },
      ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hasCategoryErrors = !!workflowAIModel?.category?.items.some(category => category.error);

    if (postedWorkflowAIModel && submitted && workflowAIModel && !hasCategoryErrors) {
      setCurrentStep(3);
    } else if (postedWorkflowAIModel && submitted && workflowAIModel && hasCategoryErrors) {
      setSubmitted(false);

      const categoryAPIMappedErrors =
        workflowAIModel?.category?.items.reduce(
          (acc, category, index) => {
            if (
              category.error &&
              (category.error.field || category.error.title || category.error.description)
            ) {
              acc[`category-${index}`] = {
                field: category.error.field,
                title: category.error.title,
                description: category.error.description,
              };
            }
            return acc;
          },
          {} as Record<string, IWorkflowAIModelCategoryError>,
        ) || {};

      setCategoryAPIErrors(categoryAPIMappedErrors);

      const categoryErrors = Object.keys(categoryAPIMappedErrors).reduce(
        (acc, key) => {
          acc[key] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      setErrors(categoryErrors);
    }
    // eslint-disable-next-line
  }, [submitted, postedWorkflowAIModel]);

  const onGoBackClick = () => {
    form.resetFields();
    setPreviousStep(2);
    setCurrentStep(1);
  };

  const onCollapseCategory = (index: number) => {
    setCategories(
      categories.map((category, i) =>
        i === index ? { ...category, collapsed: !category.collapsed } : category,
      ),
    );
    setOpenDeleteCategoryDropdown(false);
  };

  const onValuesChange = () => {
    const fieldsErrors = form.getFieldsError();
    const mappedErrors = fieldsErrors.reduce(
      (accErrors, error) => ({
        ...accErrors,
        ...(!!error.errors[0] && { [error.name[0]]: error.errors[0] }),
      }),
      {},
    );

    setErrors(mappedErrors);
    dispatch(setWorkflowFormDirtyAction(true));
  };

  const onAddCategoryClick = () => {
    const newCategories = [...categories];
    newCategories.push({
      description: "",
      collapsed: false,
      showDeleteConfirmation: false,
      examples: [""],
    });
    setCategories(newCategories);
  };

  const onDeleteCategoryClick = (e: MouseEvent<HTMLSpanElement>, index: number) => {
    e.stopPropagation();
    setOpenDeleteCategoryDropdown(false);
    setCategories(
      categories.map((category, _index) =>
        _index === index ? { ...category, showDeleteConfirmation: true } : category,
      ),
    );
  };

  const onDeleteCategoryConfirm = (index: number) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
    updatedCategories.forEach((category, _index) => {
      form.setFieldValue("category-" + _index, category.description);
    });
  };

  const onDeleteCategoryCancel = (index: number) => {
    setCategories(
      categories.map((category, _index) =>
        _index === index ? { ...category, showDeleteConfirmation: false } : category,
      ),
    );
  };

  const onCategoryChange = (value: string, categoryIndex: number) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].description = value;
    setCategories(newCategories);
  };

  const onExampleChange = (categoryIndex: number, exampleIndex: number, value: string) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].examples[exampleIndex] = value;
    setCategories(newCategories);
  };

  const onAddExampleClick = (index: number) => {
    const newCategories = [...categories];
    newCategories[index].examples?.push("");
    setCategories(newCategories);
  };

  const onDeleteExampleClick = (categoryIndex: number, exampleIndex: number) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].examples?.splice(exampleIndex, 1);
    setCategories(newCategories);
  };

  const onExclusionsChange = (value: string, categoryIndex: number) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].exclusion = value;
    setCategories(newCategories);
  };

  const sendWorkflowAIModel = () => {
    setSubmitted(true);
    const payload = {
      step: "category",
      workflow_id: workflowId,
      category: {
        items: categories.map(category => ({
          description: category.description,
          examples: category.examples.filter(example => example),
          exclusion: category.exclusion,
        })),
      },
    } as IWorkflowAIModelPayload;

    postWorkflowsAIModels(payload);
  };

  const onFinish = () => {
    if (previousStep > 1) {
      setShowUpdatingStepModal(true, sendWorkflowAIModel);
    } else sendWorkflowAIModel();
  };

  const categoryRequiredRules = [
    {
      required: true,
      message: <FormErrorMessage>{t(`${transBase}:categoryRequiredError`)}</FormErrorMessage>,
      validateTrigger: "onChange",
    },
    {
      // If contains only whitespaces
      pattern: /\S+/,
      validateTrigger: "onSubmit",
      message: <FormErrorMessage>{t(`${transBase}:categoryRequiredError`)}</FormErrorMessage>,
    },
  ];

  const getMinLengthChipProps = (category: ICategory, index: number) =>
    ({
      _type:
        (submitted || !!form.getFieldError("category-" + index).length) &&
        category.description.length >= CategoryDescriptionMinLimit
          ? "success"
          : !!form.getFieldError("category-" + index).length &&
              category.description.length < CategoryDescriptionMinLimit
            ? "error"
            : "grey",
      icon:
        (submitted || !!form.getFieldError("category-" + index).length) &&
        category.description.length >= CategoryDescriptionMinLimit
          ? CheckIcon
          : undefined,
      iconPosition: "left",
    }) as IMinLengthChipProps;

  return (
    <>
      {postingWorkflowAIModel ? (
        <AINLPModalLoader text={t(`${rightPanelBase}:loader`)} />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={onValuesChange}
          className={s.bbAIWorkflowStep3}
        >
          <div>
            {categories.map((category, index) => (
              <Accordion
                key={index}
                type="white"
                collapsed={category.collapsed}
                setCollapsed={() => onCollapseCategory(index)}
                headerSuffix={
                  index === 0 ? undefined : (
                    <Dropdown
                      open={openDeleteCategoryDropdown}
                      setOpen={(open: boolean, e?: MouseEvent<HTMLDivElement>) => {
                        e?.stopPropagation();
                        setOpenDeleteCategoryDropdown(open);
                      }}
                      trigger={<MoreActionsIcon />}
                      dropdownClassName={s.bbAIModalDropdown}
                      menuClassName={s.bbAIModalDropdownMenu}
                      placement="bottomRight"
                    >
                      <span
                        className={s.bbAIModalDropdownText}
                        onClick={e => onDeleteCategoryClick(e, index)}
                      >
                        <DeleteIcon />
                        {t(`${transBase}:categoryDeleteLabel`)}
                      </span>
                    </Dropdown>
                  )
                }
                title={t(`${transBase}:category`) + (index + 1)}
                body={
                  <>
                    {category.showDeleteConfirmation && (
                      <DeleteConfirmation
                        message={t(`${transBase}:categoryDeleteConfirmation`)}
                        onConfirm={() => onDeleteCategoryConfirm(index)}
                        onCancel={() => onDeleteCategoryCancel(index)}
                        className={s.bbDeleteConfirmation}
                      />
                    )}
                    <Label text={t(`${transBase}:categoryLabel`)} className={s.bbCategoryLabel} />
                    <Form.Item
                      name={"category-" + index}
                      rules={[
                        {
                          max: CategoryDescriptionMaxLimit,
                          min: CategoryDescriptionMinLimit,
                          validateTrigger: "onBlur",
                          message: (
                            <FormErrorMessage>
                              {t(`${transBase}:categoryLengthError`, {
                                min: CategoryDescriptionMinLimit,
                                max: CategoryDescriptionMaxLimit,
                              })}
                            </FormErrorMessage>
                          ),
                        },
                        ...(index === 0 ? categoryRequiredRules : []),
                      ]}
                    >
                      <TextareaWhite
                        _size="lg"
                        maxLengthChipMinus
                        value={category.description}
                        maxLength={CategoryDescriptionMaxLimit}
                        minLength={CategoryDescriptionMinLimit}
                        minLengthChipProps={getMinLengthChipProps(category, index)}
                        placeholder={t(`${transBase}:categoryPlaceholder`)}
                        hasError={errors.hasOwnProperty("category-" + index)}
                        hideMaxLengthChip={
                          category.description.length <= CategoryDescriptionMaxLimit
                        }
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          onCategoryChange(e.target.value, index)
                        }
                        className={s.bbCategory}
                        wrapperClassName={classNames(s.bbCategoryWrapper, {
                          [s.bbCategoryWrapperError]: errors.hasOwnProperty("category-" + index),
                        })}
                      />
                    </Form.Item>
                    {categoryAPIErrors &&
                      categoryAPIErrors[`category-${index}`]?.field === "description" && (
                        <FormErrorMessage>
                          <div>
                            <div>{categoryAPIErrors[`category-${index}`].title}</div>
                            <div>{categoryAPIErrors[`category-${index}`].description}</div>
                          </div>
                        </FormErrorMessage>
                      )}
                    <div className={s.bbExamplesTitle}>
                      {t(`${transBase}:examples`)}
                      <span>{t(`${transBase}:optional`)}</span>
                    </div>
                    {category.examples?.map((example, exampleIndex) => (
                      <div className={s.bbExampleWrapper} key={exampleIndex}>
                        <Label
                          text={t(`${transBase}:exampleLabel`) + (exampleIndex + 1)}
                          className={s.bbExampleLabel}
                        />
                        <div className={s.bbExampleContent}>
                          <Form.Item
                            name={"category-" + index + "-example-" + exampleIndex}
                            className={s.bbExampleFormItem}
                            rules={[
                              {
                                max: ExampleDescriptionMaxLimit,
                                message: (
                                  <FormErrorMessage>
                                    {t(`${transBase}:exampleLengthError`, {
                                      max: ExampleDescriptionMaxLimit,
                                    })}
                                  </FormErrorMessage>
                                ),
                              },
                            ]}
                          >
                            <Input
                              _size="md"
                              value={example}
                              hideMaxLengthChip
                              maxLength={ExampleDescriptionMaxLimit}
                              placeholder={
                                exampleIndex === 0 ? t(`${transBase}:examplePlaceholder`) : ""
                              }
                              hasError={errors.hasOwnProperty(
                                "category-" + index + "-example-" + exampleIndex,
                              )}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onExampleChange(index, exampleIndex, e.target.value)
                              }
                              wrapperClassName={classNames(s.bbExample, {
                                [s.bbExampleError]: errors.hasOwnProperty(
                                  "category-" + index + "-example-" + exampleIndex,
                                ),
                              })}
                            />
                          </Form.Item>
                          {exampleIndex !== 0 && (
                            <Button
                              _type="secondary"
                              onClick={() => onDeleteExampleClick(index, exampleIndex)}
                              className={s.bbDeleteExampleBtn}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                    {category.examples.length < 3 && (
                      <Button
                        _type="on-surface"
                        onClick={() => onAddExampleClick(index)}
                        className={s.bbAddExampleBtn}
                      >
                        <PlusIcon />
                        {t(`${transBase}:addExample`)}
                      </Button>
                    )}
                    <div className={parentStyles.bbDivider}></div>
                    <Label text={t(`${transBase}:exclusionLabel`)} className={s.bbCategoryLabel} />
                    <span className={parentStyles.bbOptional}>{t(`${transBase}:optional`)}</span>
                    <Form.Item
                      name={"category-" + index + "-exclusions"}
                      className={s.bbExclusionsFormItem}
                      rules={[
                        {
                          max: ExclusionsDescriptionMaxLimit,
                          message: (
                            <FormErrorMessage>
                              {t(`${transBase}:exclusionLengthError`, {
                                max: ExclusionsDescriptionMaxLimit,
                              })}
                            </FormErrorMessage>
                          ),
                        },
                      ]}
                    >
                      <TextareaWhite
                        _size="lg"
                        hideMaxLengthChip
                        value={category.exclusion}
                        maxLength={ExclusionsDescriptionMaxLimit}
                        placeholder={t(`${transBase}:exclusionPlaceholder`)}
                        hasError={errors.hasOwnProperty("exclusions")}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          onExclusionsChange(e.target.value, index)
                        }
                        className={s.bbCategory}
                        wrapperClassName={classNames(s.bbCategoryWrapper, {
                          [s.bbCategoryWrapperError]: errors.hasOwnProperty("exclusions"),
                        })}
                      />
                    </Form.Item>
                  </>
                }
                className={s.bbAIWorkflowStep3CategoryAccordion}
              />
            ))}
          </div>
          {categories.length < MAX_CATEGORIES_AMOUNT && (
            <Button _type="on-surface" onClick={onAddCategoryClick} className={s.bbAddCategoryBtn}>
              <PlusIcon />
              {t(`${transBase}:addCategory`)}
            </Button>
          )}
          <Form.Item>
            <div className={s.bbAIWorkflowStep3Footer}>
              <Button
                _type="on-surface"
                onClick={onGoBackClick}
                disabled={postingWorkflowAIModel}
                className={s.bbAIWorkflowStep3BackBtn}
                data-cy="ai-tagging-workflow-step3-backBtn"
              >
                <ChevronLeftIcon />
                {t(`${transBase}:backBtn`)}
              </Button>
              <Button
                type="submit"
                _type="primary"
                disabled={postingWorkflowAIModel}
                className={s.bbAIWorkflowStep3OkBtn}
                data-cy="ai-tagging-workflow-step3-okBtn"
              >
                {t(`${transBase}:okBtn`)}
                <ChevronRightIcon />
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default Step3;
