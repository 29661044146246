/* eslint-disable @typescript-eslint/no-empty-function */
import dayjs, { Dayjs } from "dayjs";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  KeywordsFilter,
  CommentsFilter,
  PostTypesFilter,
  FollowersFilter,
  CampaignsFilter,
  DateRangeFilter,
  AllAuthorsFilter,
  AssetsMultiFilter,
  AssetsGroupsFilter,
  MessageTypeFilter,
  AssignUsersFilter,
  RatingReviewFilter,
  PlatformTypeFilter,
  MessageStatusFilter,
  SentimentSelectFilter,
  KeywordsDropdownBasic,
  SelectStyledSingleFormWrapper,
  DynamicSelectStyledMultiFormWrapper,
} from "@bbdevcrew/bb_ui_kit_fe";

import {
  usersSelector,
  getUsersPendingSelector,
  getUsersSuccessfulSelector,
} from "@store/users/selectors";
import {
  commentTagsSelector,
  getCommentTagsFailedSelector,
  getCommentTagsProcessingSelector,
  getCommentTagsSuccessfulSelector,
} from "@store/commentTags/selectors";
import {
  predefinedFiltersSelector,
  getPredefinedFiltersFailedSelector,
  getPredefinedFiltersPendingSelector,
  getPredefinedFiltersSuccessfulSelector,
} from "@store/savedFilters/selectors";
import { meSelector } from "@store/me/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import {
  fetchedPostTagsForFilterSelector,
  fetchingPostTagsForFilterFailSelector,
  fetchingPostTagsForFilterSelector,
  postTagsForFilterSelector,
} from "@store/postTags/selectors";

import { getUsersAction } from "@store/users/actions";
import { getCommentTagsAction } from "@store/commentTags/actions";
import { getPredefinedFiltersAction } from "@store/savedFilters/actions";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { getPostTagsForFilterAction } from "@store/postTags/actions";

import {
  ISelectProps,
  IDynamicFilter,
  getSentimentOptions,
  IDateRangeProps,
  IFormFilterRequest,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { TFunction } from "i18next";
import { FormInstance } from "antd";
import { dateList } from "@utils/dates";
import { isTTorYTEnabled, isDeepEquals } from "@utils/filters";
import { useYTSelected } from "@utils/useYTselected";

export const commonProps = {
  options: [],
  noBorder: true,
  showArrow: true,
  allowClear: true,
  mode: "multiple",
  isMultiPaste: true,
  filterOption: false,
  dropdownMatchSelectWidth: false,
  isMultiPasteCustomAllowed: true,
  onFocus: () => {},
  onSearch: () => {},
};

export const MIN_DATE_RANGE = "2020-01-01";
export const YT_AVAILABLE_DAYS = 30;

export const useGetDefaultFiltersConfig = (
  t: TFunction,
  isInModal?: boolean,
  form?: FormInstance<IFormFilterRequest>,
  hiddenPlatformTypes: string[] = [],
) => {
  const dispatch = useDispatch();

  const dateRangeFromForm = form?.getFieldValue("data_range_option");
  const platformTypesFromForm = form?.getFieldValue("platform_types") || [];

  const [platformTypes, setPlatformTypes] = useState(platformTypesFromForm);
  const isYTSelected = useYTSelected(platformTypes);
  const [selectedRangeOption, setSelectedRangeOption] = useState(dateRangeFromForm);
  const [customDates, setCustomDates] = useState(form?.getFieldValue("dates") || []);

  const me = useSelector(meSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);
  const predefinedFiltersFetched = useSelector(getPredefinedFiltersSuccessfulSelector);
  const fetchingPredefinedFilters = useSelector(getPredefinedFiltersPendingSelector);
  const predefinedFiltersFetchFailed = useSelector(getPredefinedFiltersFailedSelector);
  const users = useSelector(usersSelector);
  const usersFetching = useSelector(getUsersPendingSelector);
  const usersFetched = useSelector(getUsersSuccessfulSelector);
  const commentTags = useSelector(commentTagsSelector);
  const commentTagsFetching = useSelector(getCommentTagsProcessingSelector);
  const commentTagsFetched = useSelector(getCommentTagsSuccessfulSelector);
  const commentTagsFetchFailed = useSelector(getCommentTagsFailedSelector);
  const postTagsForFilter = useSelector(postTagsForFilterSelector);
  const fetchingPostTagsForFilter = useSelector(fetchingPostTagsForFilterSelector);
  const fetchedPostTagsForFilter = useSelector(fetchedPostTagsForFilterSelector);
  const fetchingPostTagsForFilterFail = useSelector(fetchingPostTagsForFilterFailSelector);

  const isIGFollowersCountEnabled = me?.client?.ig_followers_count_enabled;
  const isTTAuthorMetricsEnabled = me?.client?.tt_author_metrics_enabled;

  const postTypesOptions = me?.post_types?.items || [];
  const messageTypesOptions = me?.message_types?.items || [];

  useEffect(() => {
    const isSameDateRange = isDeepEquals(selectedRangeOption, dateRangeFromForm);

    if (!isSameDateRange) setSelectedRangeOption(dateRangeFromForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeFromForm]);

  useEffect(() => {
    const isSamePlatformTypes = isDeepEquals(platformTypes, platformTypesFromForm);

    if (!isSamePlatformTypes) setPlatformTypes(platformTypesFromForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformTypesFromForm]);

  const getUsers = useCallback(() => dispatch(getUsersAction()), [dispatch]);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    (payload: IGetAutocompleteOptionsPayload) => dispatch(getAutocompleteOptionsAction(payload)),
    [dispatch],
  );

  const getPredefinedFilters = useCallback(
    () => dispatch(getPredefinedFiltersAction()),
    [dispatch],
  );

  const getCommentTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  const getPostTags = useCallback(() => dispatch(getPostTagsForFilterAction()), [dispatch]);

  const getFilteredSentimentOptions = () => {
    const allOptions = getSentimentOptions(t);

    return isTTorYTEnabled(me)
      ? allOptions
      : allOptions.filter(option => option.value !== "not_applicable");
  };

  const isDateDisabled = (d: Dayjs) => {
    const datesUnavailable = !d || d.isBefore(MIN_DATE_RANGE);

    if (isYTSelected) {
      const ytAvailableDateRange = dayjs().subtract(YT_AVAILABLE_DAYS, "days").format("YYYY-MM-DD");

      return datesUnavailable || d.isBefore(ytAvailableDateRange);
    }

    return datesUnavailable;
  };

  const isPlatformDisabled = (value: string) => {
    const ytAvailableDateRange = dayjs().subtract(YT_AVAILABLE_DAYS, "days").format("YYYY-MM-DD");

    return (
      value === "youtube" &&
      (["", "last_12_months", "all_time"].includes(dateRangeFromForm) ||
        (dateRangeFromForm === "custom" &&
          !!customDates?.length &&
          customDates[0].isBefore(ytAvailableDateRange)))
    );
  };

  const onPlatformTypesChange = (newValues: string[]) => {
    form?.setFieldValue("platform_types", newValues);
    setPlatformTypes(newValues);
  };

  const defaultFilters = [
    {
      name: "data_range_option",
      type: "asset",
      component: DateRangeFilter,
      label: t("components:filters:dates:label"),
      tooltip: t("components:filters:dates:tooltip"),
      props: {
        allowClear: !isYTSelected,
        dateList: dateList(t, isYTSelected),
        defaultValue: isYTSelected ? "last_30_days" : "",
        isDateDisabled,
        onChange: setSelectedRangeOption,
        onChangeDataRangeOption: setSelectedRangeOption,
        onCalendarChange: setCustomDates,
        disabledDateTooltip: t("components:filters:dates:ytNoAnalysis"),
        use12HoursFormat: me?.calendar_12_hour_format,
        dropdownMatchSelectWidth: false,
      },
    },
    {
      name: "platform_types",
      type: "asset",
      component: PlatformTypeFilter,
      label: t("components:filters:platform_types:label"),
      props: {
        hiddenOptions: hiddenPlatformTypes,
        options: [],
        isDisabled: isPlatformDisabled,
        onChange: onPlatformTypesChange,
        disabledTooltip: t("components:filters:dates:ytNoAnalysisForDates"),
        placeholder: t("components:filters:platform_types:placeholder"),
      },
    },
    {
      name: "asset_group_ids",
      type: "asset",
      component: AssetsGroupsFilter,
      label: t("components:filters:asset_group:label"),
      tooltip: t("components:filters:asset_group:tooltip"),
      props: {
        ...commonProps,
        predefinedFilters,
        autocompleteOptions,
        getPredefinedFilters,
        getAutocompleteOptions,
        predefinedFiltersFetched,
        fetchingPredefinedFilters,
        predefinedFiltersFetchFailed,
        placeholder: t("components:filters:asset_group:placeholder"),
      },
    },
    {
      name: "assetsInfix",
      type: "asset",
    },
    {
      name: "asset_ids",
      type: "asset",
      component: AssetsMultiFilter,
      label: t("components:filters:asset:label"),
      props: {
        ...commonProps,
        autocompleteOptions,
        getAutocompleteOptions,
        sanitizeFieldName: "asset",
        additionalLabelFields: ["id"], // additionalLabelFields store the fields that will be displayed in the option label
        placeholder: t("components:filters:asset:placeholder"),
      },
    },
    {
      name: "sentiments",
      type: "comment",
      component: SentimentSelectFilter,
      label: t("components:filters:sentiment:label"),
      tooltip: t("components:filters:sentiment:tooltip"),
      groupLabel: t("components:filters:groups:comments"),
      props: {
        options: getFilteredSentimentOptions(),
        noBorder: true,
        showArrow: true,
        allowClear: true,
        mode: "multiple",
        dropdownMatchSelectWidth: false,
        placeholder: t("components:filters:sentiment:placeholder"),
      },
    },
    {
      name: "keyword_query",
      type: "comment",
      component: KeywordsFilter,
      label: t("components:filters:keywords:label"),
      props: {
        options: [],
        limited: true,
        showTranslationSwitch: true,
        dropdownMatchSelectWidth: !!isInModal,
        selectedLabel: t("components:filters:specifiedTag"),
      },
    },
    {
      name: "comment_tags",
      type: "comment",
      component: CommentsFilter,
      label: t("components:filters:comment_tags:label"),
      props: {
        ...commonProps,
        onFocus: undefined,
        onSearch: undefined,
        commentTags,
        commentTagsFetching,
        commentTagsFetched,
        commentTagsFetchFailed,
        getCommentTags,
        placeholder: t("components:filters:comment_tags:placeholder"),
      },
    },
    {
      name: "message_types",
      type: "asset",
      component: MessageTypeFilter,
      label: t("components:filters:message_types:label"),
      props: {
        options: messageTypesOptions,
        placeholder: t("components:filters:message_types:placeholder"),
      },
    },
    {
      name: "languages",
      type: "comment",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:languages:label"),
      props: {
        autocompleteOptions,
        getAutocompleteOptions,
        sanitizeFieldName: "language",
        placeholder: t("components:filters:languages:placeholder"),
      },
    },
    {
      name: "review_types",
      type: "comment",
      component: RatingReviewFilter,
      isHidden: !me?.review_filters?.enabled,
      label: t("components:filters:review_filters:label"),
      props: {
        ...commonProps,
        options: me?.review_filters?.items,
        placeholder: t("components:filters:review_filters:placeholder"),
      },
    },
    {
      name: "assigned_users",
      type: "comment",
      component: AssignUsersFilter,
      label: t("components:filters:assigned_users:label"),
      props: {
        ...commonProps,
        users,
        getUsers,
        usersFetched,
        usersFetching,
        onFocus: undefined,
        onSearch: undefined,
        placeholder: t("components:filters:assigned_users:placeholder"),
      },
    },
    {
      name: "visibility_type",
      type: "asset",
      component: SelectStyledSingleFormWrapper,
      label: t("components:filters:visibility_type:label"),
      props: {
        fieldName: "visibility_type",
        _size: "sm",
        options: [
          {
            id: "visible",
            label: t("components:filters:visibility_type:options:visible"),
          },
          {
            id: "hidden",
            label: t("components:filters:visibility_type:options:hidden"),
          },
          {
            id: "deleted",
            label: t("components:filters:visibility_type:options:deleted"),
          },
        ],
        placeholder: t("components:filters:visibility_type:placeholder"),
      },
    },
    {
      name: "listen_sources",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      isHidden: !me?.has_sources,
      label: t("components:filters:listen_source:label"),
      props: {
        ...commonProps,
        autocompleteOptions,
        getAutocompleteOptions,
        fieldName: "listen_source",
        sanitizeFieldName: "listen_source",
        placeholder: t("components:filters:listen_source:placeholder"),
      },
    },
    {
      name: "comment_ids",
      type: "comment",
      component: KeywordsFilter,
      label: t("components:filters:comment_ids:label"),
      props: {
        name: "comment_ids",
        options: [],
        DropdownVariation: KeywordsDropdownBasic,
        showTranslationSwitch: false,
        dropdownMatchSelectWidth: !!isInModal,
        selectedLabel: t("components:filters:specifiedTag"),
      },
    },
    {
      name: "message_statuses",
      type: "asset",
      component: MessageStatusFilter,
      label: t("components:filters:message_statuses:label"),
      props: {
        placeholder: t("components:filters:message_statuses:placeholder"),
      },
    },
    {
      name: "author_ids",
      type: "comment",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:author_ids:label"),
      tooltip: t("components:filters:author_ids:tooltip"),
      groupLabel: t("components:filters:groups:users"),
      props: {
        sanitizeFieldName: "author",
        additionalLabelFields: ["id"],
        autocompleteOptions,
        getAutocompleteOptions,
        ...commonProps,
        placeholder: t("components:filters:author_ids:placeholder"),
      },
    },
    {
      name: "author_names",
      type: "comment",
      component: AllAuthorsFilter,
      label: t("components:filters:author_names:label"),
      tooltip: t("components:filters:author_names:tooltip"),
      props: {
        ...commonProps,
        dropdownMatchSelectWidth: !!isInModal,
        placeholder: t("components:filters:author_names:placeholder"),
      },
    },
    ...(isIGFollowersCountEnabled || isTTAuthorMetricsEnabled
      ? [
          {
            name: "author_followers",
            type: "asset",
            component: FollowersFilter,
            label: isTTAuthorMetricsEnabled
              ? t("components:filters:author_followers:label")
              : t("components:filters:author_followers:IGLabel"),
            tooltip: isTTAuthorMetricsEnabled
              ? t("components:filters:author_followers:tooltip")
              : t("components:filters:author_followers:IGTooltip"),
            props: {
              placeholder: t("components:filters:author_followers:placeholder"),
            },
          },
        ]
      : []),
    {
      name: "post_types",
      type: "asset",
      component: PostTypesFilter,
      label: t("components:filters:post_types:label"),
      groupLabel: t("components:filters:groups:posts"),
      props: {
        options: postTypesOptions,
        placeholder: t("components:filters:post_types:placeholder"),
      },
    },
    {
      name: "post_ids",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:post_ids:label"),
      tooltip: t("components:filters:post_ids:tooltip"),
      props: {
        sanitizeFieldName: "post",
        additionalLabelFields: ["id"],
        autocompleteOptions,
        getAutocompleteOptions,
        ...commonProps,
        dropdownMatchSelectWidth: true,
        placeholder: t("components:filters:post_ids:placeholder"),
      },
    },
    {
      name: "post_tags",
      type: "asset",
      component: CommentsFilter,
      label: t("components:filters:post_tags:label"),
      tooltip: t("components:filters:post_tags:tooltip"),
      props: {
        ...commonProps,
        onFocus: undefined,
        onSearch: undefined,
        commentTags: postTagsForFilter,
        commentTagsFetching: fetchingPostTagsForFilter,
        commentTagsFetched: fetchedPostTagsForFilter,
        commentTagsFetchFailed: fetchingPostTagsForFilterFail,
        getCommentTags: getPostTags,
        placeholder: t("components:filters:post_tags:placeholder"),
      },
    },
    {
      name: "post_keyword_query",
      type: "asset",
      component: KeywordsFilter,
      label: t("components:filters:post_keywords:label"),
      tooltip: t("components:filters:post_keywords:tooltip"),
      props: {
        options: [],
        limited: true,
        showTranslationSwitch: true,
        dropdownMatchSelectWidth: !!isInModal,
        selectedLabel: t("components:filters:specifiedTag"),
      },
    },
    {
      name: "campaign_ids",
      type: "asset",
      component: CampaignsFilter,
      label: t("components:filters:campaign:label"),
      groupLabel: t("components:filters:groups:campaigns"),
      props: {
        ...commonProps,
        autocompleteOptions,
        getAutocompleteOptions,
        additionalLabelFields: ["id"],
        onFocus: undefined,
        onSearch: undefined,
        placeholder: t("components:filters:campaign:placeholder"),
      },
    },
    {
      name: "adset_ids",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:adset:label"),
      props: {
        sanitizeFieldName: "adset",
        additionalLabelFields: ["id"],
        autocompleteOptions,
        getAutocompleteOptions,
        placeholder: t("components:filters:adset:placeholder"),
      },
    },
    {
      name: "ad_ids",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:ad:label"),
      props: {
        sanitizeFieldName: "ads",
        additionalLabelFields: ["id"],
        dropdownPlacement: "topRight",
        autocompleteOptions,
        getAutocompleteOptions,
        ...commonProps,
        placeholder: t("components:filters:ad:placeholder"),
      },
    },
    {
      name: "targetings",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:targetings:label"),
      tooltip: t("components:filters:targetings:tooltip"),
      props: {
        autocompleteOptions,
        getAutocompleteOptions,
        sanitizeFieldName: "ads_targeting",
        dropdownPlacement: "topRight",
        ...commonProps,
        placeholder: t("components:filters:targetings:placeholder"),
      },
    },
    // eslint-disable-next-line
  ] as IDynamicFilter<ISelectProps | IDateRangeProps | PropsWithChildren<any>>[];

  return defaultFilters;
};
