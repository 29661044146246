import { action } from "typesafe-actions";

import {
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
  CREATE_WORKFLOW,
  CREATE_WORKFLOW_SUCCESS,
  CREATE_WORKFLOW_FAILURE,
  EDIT_WORKFLOW,
  EDIT_WORKFLOW_SUCCESS,
  EDIT_WORKFLOW_FAILURE,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  ENABLE_WORKFLOW,
  ENABLE_WORKFLOW_SUCCESS,
  ENABLE_WORKFLOW_FAILURE,
  DISABLE_WORKFLOW,
  DISABLE_WORKFLOW_SUCCESS,
  DISABLE_WORKFLOW_FAILURE,
  SET_WORKFLOW_FORM_DIRTY,
  RESET_WORKFLOWS,
  GET_WORKFLOWS_SAVED_REPLIES,
  GET_WORKFLOWS_SAVED_REPLIES_SUCCESS,
  GET_WORKFLOWS_SAVED_REPLIES_FAILURE,
  CLEAR_MATCHING_COMMENTS,
  GET_MATCHING_COMMENTS_FAILURE,
  GET_MATCHING_COMMENTS_SUCCESS,
  GET_MATCHING_COMMENTS,
  POST_WORKFLOW_AI_MODELS,
  POST_WORKFLOW_AI_MODELS_SUCCESS,
  POST_WORKFLOW_AI_MODELS_FAILURE,
  CLEAR_POST_WORKFLOW_AI_MODELS,
  EDIT_WORKFLOW_AI_MODELS_TASK,
  EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE,
  CREATE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  GET_WORKFLOW_AI_MODELS,
  GET_WORKFLOW_AI_MODELS_SUCCESS,
  GET_WORKFLOW_AI_MODELS_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE,
  DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS,
  CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  TEST_WORKFLOW_AI_MODEL_FAILURE,
  TEST_WORKFLOW_AI_MODEL_SUCCESS,
  TEST_WORKFLOW_AI_MODEL,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS,
  DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES,
  CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK,
  CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK,
  GET_WORKFLOWS_ACCESS_USERS,
  GET_WORKFLOWS_ACCESS_USERS_SUCCESS,
  GET_WORKFLOWS_ACCESS_USERS_FAILURE,
} from "./actionTypes";
import {
  IGetWorkflowsResponseMapped,
  IWorkflowAIModelPayload,
  IWorkflowItemPayload,
  IWorkflowSavedReplyPayload,
  IWorkflowAccessUserResponse,
  IWorkflowAIModelResponse,
  IEditWorkflowAIModelTaskPayload,
  ICreateWorkflowAIModelTaskResponse,
  ITaskExampleCreationResponse,
  ITaskExampleCreationPayload,
  IWorkflowAIModelTestingResponse,
} from "./types";
import { ISavedReply } from "@store/replies/types";
import { IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const getWorkflowsAction = () => action(GET_WORKFLOWS);
export const getWorkflowsSuccessAction = (data: IGetWorkflowsResponseMapped) =>
  action(GET_WORKFLOWS_SUCCESS, data);
export const getWorkflowsFailureAction = (error: string) => action(GET_WORKFLOWS_FAILURE, error);

export const createWorkflowAction = (workflow: IWorkflowItemPayload) =>
  action(CREATE_WORKFLOW, workflow);
export const createWorkflowSuccessAction = (workflow: IWorkflowItemPayload) =>
  action(CREATE_WORKFLOW_SUCCESS, workflow);
export const createWorkflowFailureAction = () => action(CREATE_WORKFLOW_FAILURE);

export const editWorkflowAction = (workflow: IWorkflowItemPayload) =>
  action(EDIT_WORKFLOW, workflow);
export const editWorkflowSuccessAction = () => action(EDIT_WORKFLOW_SUCCESS);
export const editWorkflowFailureAction = () => action(EDIT_WORKFLOW_FAILURE);

export const deleteWorkflowAction = (id: string) => action(DELETE_WORKFLOW, id);
export const deleteWorkflowSuccessAction = () => action(DELETE_WORKFLOW_SUCCESS);
export const deleteWorkflowFailureAction = () => action(DELETE_WORKFLOW_FAILURE);

export const enableWorkflowAction = (id: string) => action(ENABLE_WORKFLOW, id);
export const enableWorkflowSuccessAction = () => action(ENABLE_WORKFLOW_SUCCESS);
export const enableWorkflowFailureAction = (id: string) => action(ENABLE_WORKFLOW_FAILURE, id);

export const disableWorkflowAction = (id: string) => action(DISABLE_WORKFLOW, id);
export const disableWorkflowSuccessAction = () => action(DISABLE_WORKFLOW_SUCCESS);
export const disableWorkflowFailureAction = (id: string) => action(DISABLE_WORKFLOW_FAILURE, id);

export const setWorkflowFormDirtyAction = (dirty: boolean) =>
  action(SET_WORKFLOW_FORM_DIRTY, dirty);

export const resetWorkflowsAction = () => action(RESET_WORKFLOWS);

export const getWorkflowsAccessUsersAction = () => action(GET_WORKFLOWS_ACCESS_USERS);
export const getWorkflowsAccessUsersSuccessAction = (data: IWorkflowAccessUserResponse[]) =>
  action(GET_WORKFLOWS_ACCESS_USERS_SUCCESS, data);
export const getWorkflowsAccessUsersFailureAction = (error: string) =>
  action(GET_WORKFLOWS_ACCESS_USERS_FAILURE, error);

export const getWorkflowsSavedRepliesAction = (payload: IWorkflowSavedReplyPayload) =>
  action(GET_WORKFLOWS_SAVED_REPLIES, payload);
export const getWorkflowsSavedRepliesSuccessAction = (data: ISavedReply[]) =>
  action(GET_WORKFLOWS_SAVED_REPLIES_SUCCESS, data);
export const getWorkflowsSavedRepliesFailureAction = (error: string) =>
  action(GET_WORKFLOWS_SAVED_REPLIES_FAILURE, error);

export const getMatchingCommentsAction = (payload: IFilters) =>
  action(GET_MATCHING_COMMENTS, payload);
export const getMatchingCommentsSuccessAction = (comments: IComment[], total_count: number) =>
  action(GET_MATCHING_COMMENTS_SUCCESS, { comments, total_count });
export const getMatchingCommentsFailureAction = (error: string) =>
  action(GET_MATCHING_COMMENTS_FAILURE, error);
export const clearMatchingCommentsAction = () => action(CLEAR_MATCHING_COMMENTS);

export const postWorkflowsAIModelsAction = (payload: IWorkflowAIModelPayload) =>
  action(POST_WORKFLOW_AI_MODELS, payload);
export const postWorkflowsAIModelsSuccessAction = (response: IWorkflowAIModelResponse) =>
  action(POST_WORKFLOW_AI_MODELS_SUCCESS, response);
export const postWorkflowsAIModelsFailureAction = (error: string) =>
  action(POST_WORKFLOW_AI_MODELS_FAILURE, error);
export const clearPostWorkflowsAIModelsAction = () => action(CLEAR_POST_WORKFLOW_AI_MODELS);

export const getWorkflowsAIModelsAction = (id: string) => action(GET_WORKFLOW_AI_MODELS, id);
export const getWorkflowsAIModelsSuccessAction = (response: IWorkflowAIModelResponse) =>
  action(GET_WORKFLOW_AI_MODELS_SUCCESS, response);
export const getWorkflowsAIModelsFailureAction = (error: string) =>
  action(GET_WORKFLOW_AI_MODELS_FAILURE, error);

export const createWorkflowsAIModelTaskAction = (
  workflowId: string,
  payload: IEditWorkflowAIModelTaskPayload,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK, { workflowId, payload });
export const createWorkflowsAIModelTaskSuccessAction = (
  response: ICreateWorkflowAIModelTaskResponse,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK_SUCCESS, response);
export const createWorkflowsAIModelTaskFailureAction = (error: string) =>
  action(CREATE_WORKFLOW_AI_MODELS_TASK_FAILURE, error);
export const clearCreateWorkflowsAIModelTaskAction = () =>
  action(CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK);

export const editWorkflowsAIModelsTaskAction = (
  workflowId: string,
  taskId: string,
  payload: IEditWorkflowAIModelTaskPayload,
) => action(EDIT_WORKFLOW_AI_MODELS_TASK, { workflowId, taskId, payload });
export const editWorkflowsAIModelsTaskSuccessAction = (response: IWorkflowAIModelResponse) =>
  action(EDIT_WORKFLOW_AI_MODELS_TASK_SUCCESS, response);
export const editWorkflowsAIModelsTaskFailureAction = (error: string) =>
  action(EDIT_WORKFLOW_AI_MODELS_TASK_FAILURE, error);
export const clearEditWorkflowAIModelsTaskAction = () => action(CLEAR_EDIT_WORKFLOW_AI_MODELS_TASK);

export const deleteWorkflowsAIModelsTaskAction = (workflowId: string, taskId: string) =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK, { workflowId, taskId });
export const deleteWorkflowsAIModelsTaskSuccessAction = () =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK_SUCCESS);
export const deleteWorkflowsAIModelsTaskFailureAction = (error: string) =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK_FAILURE, error);

export const createWorkflowAIModelsTaskExamplesAction = (
  workflowId: string,
  taskId: string,
  payload: ITaskExampleCreationPayload,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES, { workflowId, taskId, payload });
export const createWorkflowAIModelsTaskExamplesSuccessAction = (
  response: ITaskExampleCreationResponse,
) => action(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_SUCCESS, response);
export const createWorkflowAIModelsTaskExamplesFailureAction = (error: string) =>
  action(CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES_FAILURE, error);
export const clearCreateWorkflowAIModelsTaskExamplesAction = () =>
  action(CLEAR_CREATE_WORKFLOW_AI_MODELS_TASK_EXAMPLES);

export const updateWorkflowAIModelsTaskExamplesAction = (
  workflowId: string,
  taskId: string,
  exampleId: string,
  payload: ITaskExampleCreationPayload,
) => action(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE, { workflowId, taskId, exampleId, payload });
export const updateWorkflowAIModelsTaskExamplesSuccessAction = (
  response: ITaskExampleCreationResponse,
) => action(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS, response);
export const updateWorkflowAIModelsTaskExamplesFailureAction = (error: string) =>
  action(EDIT_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE, error);

export const deleteWorkflowAIModelsTaskExamplesAction = (
  workflowId: string,
  taskId: string,
  exampleId: string,
) => action(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE, { workflowId, taskId, exampleId });
export const deleteWorkflowAIModelsTaskExamplesSuccessAction = (
  response: ITaskExampleCreationResponse,
) => action(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_SUCCESS, response);
export const deleteWorkflowAIModelsTaskExamplesFailureAction = (error: string) =>
  action(DELETE_WORKFLOW_AI_MODELS_TASK_EXAMPLE_FAILURE, error);

export const testWorkflowAIModelAction = (workflowId: string, content: string) =>
  action(TEST_WORKFLOW_AI_MODEL, { workflowId, content });
export const testWorkflowAIModelSuccessAction = (data: IWorkflowAIModelTestingResponse) =>
  action(TEST_WORKFLOW_AI_MODEL_SUCCESS, data);
export const testWorkflowAIModelFailureAction = (error: string) =>
  action(TEST_WORKFLOW_AI_MODEL_FAILURE, error);
