import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import { AmPmType, WeekdayType } from "@store/notifications/types";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { IObjectOption, IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { ISavedReply } from "@store/replies/types";
import { IRequestState } from "@store/types";
import { IMessage } from "@components/control-panel/workflows/creationModal/AINLPModal/AINLPModal.types"; // eslint-disable-line

export type WorkflowsActions = ActionType<typeof actions>;

export type WorkflowItemCategoryType =
  | "tag"
  | "post_tag"
  | "archive"
  | "assign"
  | "sentiment"
  | "hide"
  | "email_alert"
  | "zendesk"
  | "reply"
  | "nlp"
  | "nlp_tag";

export type WorkflowGroupType = "all" | "organize" | "analyze" | "protect" | "engage";

export type WorkflowGroupsAndCategoriesType = WorkflowGroupType | WorkflowItemCategoryType;

export interface ITimeFrame {
  period?: AmPmType;
  time?: string;
}

export interface ITimeFrameTrigger {
  start_time: ITimeFrame;
  end_time: ITimeFrame;
  week_days: WeekdayType[];
  timezone: string;
}

export type IWorkflowKeywordsTrigger = string[];
export type IWorkflowTrigger = {
  keyword_query?: IWorkflowKeywordsTrigger;
  comment_tags?: string[];
  time_frame?: ITimeFrameTrigger;
  filters?: IFilters;
};

export type IWorkflowSentimentAction = "positive" | "negative" | "neutral";
export type IWorkflowTagAction = string[];

export enum EmailAlertRangeEnum {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  DYNAMIC = "dynamic",
}

export interface IRiskIdentification {
  risked?: boolean;
  risk_lvl: "low" | "moderate" | "high" | "critical";
  impact_area?: string[];
  additional_instructions?: string;
}

export interface IWorkflowEmailAlertAction {
  category?: WorkflowItemCategoryType;
  notification_id: string;
  range_type: EmailAlertRangeEnum;
  range_number?: number;
  is_single_email?: boolean;
  user_ids: string[];
  schedule?: {
    hour?: number;
    meridiem?: AmPmType;
    week_days?: WeekdayType[];
    timezone?: string;
  };
  is_post_based?: boolean;
  is_managed?: boolean;
  risk_identification?: IRiskIdentification;
  threshold?: number;
}

export interface IWorkflowAssignAction {
  assigned_users?: string[];
}

export interface IWorkflowReplyAction {
  reply_template_id?: string;
  private_reply_template_id?: string;
  delay_private_reply?: number;
  user_private_reply_interval?: number;
  reply_types?: ["private" | "public"];
  reply_only_first_private_message?: boolean;
  allow_reply_after_last_brand_message?: boolean;
  overwrite_workflow_name_listing?: boolean;
  hide_for_external_users?: boolean;
}

export type IWorkflowAction =
  | IWorkflowTagAction
  | IWorkflowSentimentAction
  | IWorkflowEmailAlertAction
  | IWorkflowAssignAction
  | IWorkflowReplyAction;

export interface IWorkflowItem {
  id: string;
  category: WorkflowItemCategoryType;
  title: string;
  description: string;
  example?: string;
  enabled: boolean;
  is_editable: boolean;
  show_enable_checkbox: boolean;
  trigger?: IWorkflowTrigger;
  action?: IWorkflowAction;
  visibility_access?: { users: string[] };
  editability_access?: { users: string[] };
  additional_actions?: IWorkflowEmailAlertAction[];
  email_alert?: IWorkflowEmailAlertAction;
  assign?: IWorkflowAssignAction;
  reply?: {
    reply_template?: ISavedReply;
    private_reply_template?: ISavedReply;
    delay_private_reply?: number;
    user_private_reply_interval?: number;
    user_private_reply_interval_scope?: string;
    reply_types?: ["private" | "public"];
    reply_only_first_private_message?: boolean;
    allow_reply_after_last_brand_message?: boolean;
    overwrite_workflow_name_listing?: boolean;
    hide_for_external_users?: boolean;
  };
}

export interface IWorkflowCategory {
  id: string;
  label: string;
  ids: string[];
  sub_categories: IWorkflowCategory[];
  is_locked?: boolean;
  is_visible?: boolean;
}

export type IKeywordFilter = {
  filters: {
    keyword_query: IWorkflowKeywordsTrigger;
    keyword_query_in_translation: boolean;
    time_frame?: ITimeFrameTrigger;
    start_time?: string;
    end_time?: string;
    timezone?: string;
    comment_tags?: string[];
  };
};

export interface IWorkflowItemPayload {
  id?: string;
  title: string;
  description: string;
  category: WorkflowItemCategoryType;
  sentiment?: IWorkflowSentimentAction;
  tags?: IWorkflowTagAction;
  post_tags?: IWorkflowTagAction;
  enabled: boolean;
  trigger: IKeywordFilter;
  is_editable: boolean;
  reply_template_id?: number;
  example?: string;
  reply?: {
    reply_types?: ["private" | "public"];
  };
  email_alert?: IWorkflowEmailAlertAction & { schedule?: { hour?: number } };
  created_at: string;
  show_enable_checkbox: boolean;
  additional_actions?: {
    items: [
      {
        category: WorkflowItemCategoryType;
        email_alert: IWorkflowEmailAlertAction;
        notificationId: string;
      },
    ];
  };
  visibility_access?: { users: string[] };
  editability_access?: { users: string[] };
}

export interface IGetWorkflowsResponse {
  items: IWorkflowItemPayload[];
  categories: IWorkflowCategory[];
  risk_identifications: IWorkflowRiskOptions;
}

export interface IGetWorkflowsResponseMapped {
  items: IWorkflowItem[];
  categories: IWorkflowCategory[];
  risk_identifications: IWorkflowRiskOptions;
}

export interface IWorkflowRiskOptions {
  risk_lvl: { id: IRiskIdentification["risk_lvl"]; label: string }[];
  impact_area: IObjectOption[];
}

export interface IWorkflowSavedReplyPayload {
  query?: string;
  preset_query?: string;
}

export interface IWorkflowUser {
  id: string;
  first_name: string;
  last_name?: string;
  checked: boolean;
}

export interface IWorkflowAccessUserResponse {
  role_workflow: "ROLE_WORKFLOW_FULL_ACCESS" | "ROLE_WORKFLOW_VIEW_ONLY";
  items: IWorkflowAccessUser[];
}

export interface IWorkflowAccessUser {
  group_id: string;
  group_label: string;
  users: IWorkflowUser[];
}

export type WorkflowAIModelStepType = "start" | "settings" | "filters" | "category" | "finish";

export interface IWorkflowAIModelCategoryError {
  field?: string;
  title?: string;
  description?: string;
}

export interface IWorkflowAIModelTaskExample {
  id?: string;
  example: string;
  should_tag: boolean;
  score: number;
  manual?: boolean;
  tag_option?: {
    type: "managed_tag" | "custom_tag";
    id: string;
    label: string;
  };
  showAddingConfirmation?: boolean;
  showEditingConfirmation?: boolean;
  showDeleteConfirmation?: boolean;
}

export interface IWorkflowAIModelTask {
  id?: string;
  name?: string;
  description: string;
  tag_type?: string;
  tag_id?: string;
  tag_option?: {
    id: string;
    label: string;
    type: string;
  };
  examples?: IWorkflowAIModelTaskExample[];
  collapsed?: boolean;
  showEditingWindow?: boolean;
  showCreatingWindow?: boolean;
  showDeleteConfirmation?: boolean;
  openTaskDropdown?: boolean;
}

export interface IWorkflowAIModelCategory {
  description: string;
  examples: string[];
  exclusion: string;
  error: IWorkflowAIModelCategoryError;
}

export type WorkflowAIModelPurposeType = "moderation" | "responding" | "insights" | "other";

export interface IWorkflowAIModel {
  // Step 1
  settings?: {
    is_enabled?: boolean;
    workflow_title: string;
    workflow_description?: string;
    purposes: [{ type: WorkflowAIModelPurposeType; purpose_other?: string }];
    visibility_access?: { users: string[] };
    editability_access?: { users: string[] };
  };
  // Step 2
  filters?: {
    filters: IFilters;
  };
  // Step 3
  category?: {
    items: IWorkflowAIModelCategory[];
  };
  // Step 4
  tasks?: IWorkflowAIModelTask[];
}

export interface IWorkflowAIModelPayload extends IWorkflowAIModel {
  step: WorkflowAIModelStepType;
  workflow_id?: string;
  existing_workflow_id?: string;
}

export interface IWorkflowAIModelResponse extends IWorkflowAIModel {
  id?: string;
  status: "success" | "in_progress";
}

export interface IEditWorkflowAIModelTaskPayload {
  description: string;
  tag_option: {
    type: string;
    id: string;
  };
}

export interface ICreateWorkflowAIModelTaskResponse {
  id: string;
  name: string;
  description: string;
  tag_option: {
    type: string;
    id: string;
    label: string;
  };
  examples: [
    {
      example: string;
      should_tag: true;
      score: number;
      id: string;
      internal_score: number;
    },
  ];
  error?: {
    field: string;
    title: string;
    description: string;
  };
}

export interface ITaskExampleCreationPayload {
  example: string;
  should_tag: boolean;
  score: number;
}

export interface ITaskExampleCreationResponse {
  example: string;
  should_tag: boolean;
  score: number;
  id: string;
  internal_score: number;
}

export interface IWorkflowAIModelTestingResponse {
  items: IMessage[];
}

export interface IWorkflowsState {
  workflowItems: IWorkflowItem[];
  workflowCategories: IWorkflowCategory[];
  workflowRiskOptions: IWorkflowRiskOptions;
  workflowsSavedReplies: ISavedReply[];
  workflowsAccessUsers: IWorkflowAccessUserResponse[];
  initialFetchDone: boolean;
  workflowAIModel: IWorkflowAIModelResponse | null;
  createdAIModelTask: ICreateWorkflowAIModelTaskResponse | null;
  testingWorkflowAIModelResult: IMessage[] | null;

  fetchingWorkflows: boolean;
  fetchedWorkflows: boolean;
  fetchingWorkflowsFailed: boolean;

  creatingWorkflow: boolean;
  createdWorkflow: IWorkflowItemPayload | null;
  creatingWorkflowFailed: boolean;

  editingWorkflow: boolean;
  editedWorkflow: boolean;
  editingWorkflowFailed: boolean;

  deletingWorkflow: boolean;
  deletedWorkflow: boolean;
  deletingWorkflowFailed: boolean;

  enablingWorkflow: boolean;
  enabledWorkflow: boolean;
  enablingWorkflowFailed: boolean;

  disablingWorkflow: boolean;
  disabledWorkflow: boolean;
  disablingWorkflowFailed: boolean;

  fetchingWorkflowsAccessUsers: boolean;
  fetchedWorkflowsAccessUsers: boolean;
  fetchingWorkflowsAccessUsersFailed: boolean;

  fetchingWorkflowsSavedReplies: boolean;
  fetchedWorkflowsSavedReplies: boolean;
  fetchingWorkflowsSavedRepliesFailed: boolean;

  fetchingWorkflowAIModel: boolean;
  fetchedWorkflowAIModel: boolean;
  fetchingWorkflowAIModelFailed: boolean;

  postingWorkflowAIModel: boolean;
  postedWorkflowAIModel: boolean;
  postingWorkflowAIModelFailed: boolean;

  creatingWorkflowAIModelTask: boolean;
  createdWorkflowAIModelTask: boolean;
  creatingWorkflowAIModelTaskFailed: boolean;

  updatingWorkflowAIModelTask: boolean;
  updatedWorkflowAIModelTask: boolean;
  updatingWorkflowAIModelTaskFailed: boolean;

  deletingWorkflowAIModelTask: boolean;
  deletedWorkflowAIModelTask: boolean;
  deletingWorkflowAIModelTaskFailed: boolean;

  creatingWorkflowAIModelTaskExample: boolean;
  createdWorkflowAIModelTaskExample: string | null;
  creatingWorkflowAIModelTaskExampleFailed: boolean;

  updatingWorkflowAIModelTaskExample: boolean;
  updatedWorkflowAIModelTaskExample: boolean;
  updatingWorkflowAIModelTaskExampleFailed: boolean;

  deletingWorkflowAIModelTaskExample: boolean;
  deletedWorkflowAIModelTaskExample: boolean;
  deletingWorkflowAIModelTaskExampleFailed: boolean;

  testingWorkflowAIModel: boolean;
  testedWorkflowAIModel: boolean;
  testingWorkflowAIModelFailed: boolean;

  matchingComments: IRequestState<{
    comments: IComment[];
    totalCount: number;
  }>;

  formDirty: boolean;
}
