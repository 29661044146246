import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import {
  Label,
  Toggle,
  Button,
  Checkbox,
  FormErrorMessage,
  InputWhiteBordered,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import AINLPModalLoader from "../AINLPModalLoader";
import WorkflowFormUserPermissions from "../../../form/WorkflowFormUserPermissions";
import SanitizedInputValidation from "@components/_common/SanitizedInputValidation";

import {
  postingWorkflowAIModelSelector,
  postedWorkflowAIModelSelector,
  workflowAIModelSelector,
} from "@store/workflows/selectors";
import { postWorkflowsAIModelsAction, setWorkflowFormDirtyAction } from "@store/workflows/actions";

import parentStyles from "../AINLPModal.module.less";
import s from "./Step1.module.less";

import { defaultValues } from "../../../helpers";
import { filterObjectKeys } from "../AINLPModal.helpers";
import { IWorkflowAIModelPayload } from "@store/workflows/types";
import { IStepProps, ITaggingWorkflowFormStep1 } from "../AINLPModal.types";
import { WORKFLOW_DESCRIPTION_REGEX } from "@components/control-panel/workflows/form/WorkflowForm.helpers"; // eslint-disable-line max-len

import { ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

const Step1: FC<IStepProps> = ({
  workflowId,
  setCurrentStep,
  previousStep,
  isEditMode,
  setShowUpdatingStepModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ITaggingWorkflowFormStep1>();
  const step1TransBase = "pages:workflows:workflowCreation:taggingFlow:rightPanel:step1";

  const [showOtherPurpose, setShowOtherPurpose] = useState(false);
  const [showCheckboxesRequiredError, setShowCheckboxesRequiredError] = useState(false);

  const workflowAIModel = useSelector(workflowAIModelSelector);
  const postingWorkflowAIModel = useSelector(postingWorkflowAIModelSelector);
  const postedWorkflowAIModel = useSelector(postedWorkflowAIModelSelector);

  const postWorkflowsAIModels = useCallback(
    (payload: IWorkflowAIModelPayload) => dispatch(postWorkflowsAIModelsAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    if (postedWorkflowAIModel) setCurrentStep(1);
  }, [postedWorkflowAIModel, setCurrentStep]);

  useEffect(() => {
    if (previousStep > 0 || isEditMode) {
      const AIModelPurposes = workflowAIModel?.settings?.purposes?.map(purpose => purpose.type);

      form.setFieldsValue({
        is_enabled: workflowAIModel?.settings?.is_enabled,
        workflow_title: workflowAIModel?.settings?.workflow_title,
        workflow_description: workflowAIModel?.settings?.workflow_description,
        responding: AIModelPurposes?.includes("responding"),
        moderation: AIModelPurposes?.includes("moderation"),
        insights: AIModelPurposes?.includes("insights"),
        other: AIModelPurposes?.includes("other"),
        purpose_other: workflowAIModel?.settings?.purposes?.find(
          purpose => purpose.type === "other",
        )?.purpose_other,
        purposes: AIModelPurposes,
        visibility_access: workflowAIModel?.settings?.visibility_access,
        editability_access: workflowAIModel?.settings?.editability_access,
      });

      setShowOtherPurpose(!!AIModelPurposes?.includes("other"));
    }
    // eslint-disable-next-line
  }, [previousStep, isEditMode]);

  const sendWorkflowAIModel = () => {
    const payload = {
      step: "settings",
      workflow_id: workflowId,
      settings: {
        ...filterObjectKeys(form.getFieldsValue(), [
          "moderation",
          "responding",
          "insights",
          "other",
          "purpose_other",
          "purposes",
        ]),
        purposes: form.getFieldsValue().purposes.map((purpose: string) => {
          if (purpose === "other") {
            return {
              type: purpose,
              purpose_other: form.getFieldsValue().purpose_other,
            };
          }
          return { type: purpose };
        }),
      },
    } as IWorkflowAIModelPayload;

    postWorkflowsAIModels(payload);
  };

  const onFinish = () => {
    if (previousStep > 0) {
      setShowUpdatingStepModal(true, sendWorkflowAIModel);
    } else sendWorkflowAIModel();
  };

  const onSubmitCapture = () => {
    if (
      !form.getFieldsValue().moderation &&
      !form.getFieldsValue().responding &&
      !form.getFieldsValue().insights &&
      !form.getFieldsValue().other
    )
      setShowCheckboxesRequiredError(true);
    else setShowCheckboxesRequiredError(false);
  };

  const onValuesChange = () => {
    if (
      showCheckboxesRequiredError &&
      (form.getFieldsValue().moderation ||
        form.getFieldsValue().responding ||
        form.getFieldsValue().insights ||
        form.getFieldsValue().other)
    )
      setShowCheckboxesRequiredError(false);

    if (form.getFieldsValue().other) setShowOtherPurpose(true);
    else setShowOtherPurpose(false);

    dispatch(setWorkflowFormDirtyAction(true));
  };

  const handleCheckboxChange = (checked: boolean, fieldName: string) => {
    const selectedOptions = form.getFieldValue("purposes") || [];
    const newSelectedOptions = checked
      ? [...selectedOptions, fieldName]
      : selectedOptions.filter((item: string) => item !== fieldName);

    form.setFieldsValue({ purposes: newSelectedOptions });
  };

  return (
    <>
      {postingWorkflowAIModel ? (
        <AINLPModalLoader />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          onSubmitCapture={onSubmitCapture}
          onFieldsChange={onValuesChange}
        >
          <div className={parentStyles.bbAIWorkflowCreationRightPanelCard}>
            <div className={s.bbAIWorkflowStep1}>
              <div className={s.bbAIWorkflowStep1Items}>
                <Form.Item name="purposes" hidden className={s.bbAIWorkflowStep1Hidden}>
                  <input />
                </Form.Item>
                <div className={s.bbAIWorkflowStep1Active}>
                  <Form.Item name="is_enabled" valuePropName="checked" initialValue={true}>
                    <Toggle id="is_enabled" name="is_enabled" />
                  </Form.Item>
                  {t(`${step1TransBase}:active`)}
                </div>
                <Form.Item
                  name="workflow_title"
                  label={t("pages:workflows:form:title:label")}
                  rules={[
                    {
                      required: true,
                      validateTrigger: "onBlur",
                      message: (
                        <FormErrorMessage>
                          {t("pages:workflows:form:title:required")}
                        </FormErrorMessage>
                      ),
                    },
                    {
                      max: 60,
                      validateTrigger: "onBlur",
                      message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
                    },
                    {
                      validator: (_, value) => {
                        if (value && !value.trim()) {
                          return Promise.reject(
                            <FormErrorMessage>
                              {t("pages:workflows:form:title:required")}
                            </FormErrorMessage>,
                          );
                        }
                        return Promise.resolve();
                      },
                      validateTrigger: "onBlur",
                    },
                    { ...SanitizedInputValidation },
                  ]}
                >
                  <InputWhiteBordered data-cy="workflow-form-title" maxLength={60} />
                </Form.Item>
                <Form.Item
                  name="workflow_description"
                  label={
                    <>
                      <span>{t("pages:workflows:form:description:label")}</span>
                      <span className={parentStyles.bbOptional}>
                        {t(`${step1TransBase}:optional`)}
                      </span>
                    </>
                  }
                  className={s.bbFormItemDescription}
                  rules={[
                    {
                      max: 300,
                      message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
                    },
                    {
                      pattern: WORKFLOW_DESCRIPTION_REGEX,
                      message: (
                        <div>
                          <FormErrorMessage>
                            {t("pages:workflows:form:description:pattern")}
                          </FormErrorMessage>
                        </div>
                      ),
                    },
                  ]}
                >
                  <InputWhiteBordered maxLength={300} />
                </Form.Item>
                <WorkflowFormUserPermissions form={form} workflow={defaultValues} />
                <div className={parentStyles.bbDivider}></div>
                <div className={s.bbAIWorkflowStep1ModelUsage}>
                  <div className={s.bbAIWorkflowStep1ModelUsageTitle}>
                    {t(`${step1TransBase}:modelUsage`)}
                  </div>
                  <div className={s.bbCheckboxWrapper}>
                    <Form.Item name="moderation" valuePropName="checked">
                      <Checkbox
                        id="moderation"
                        name="moderation"
                        _size="md"
                        className={s.bbCheckbox}
                        onChange={e => handleCheckboxChange(e.target.checked, "moderation")}
                      />
                    </Form.Item>
                    <Label
                      htmlFor="moderation"
                      className={s.bbCheckboxLabel}
                      text={t(`${step1TransBase}:moderation`)}
                    />
                  </div>
                  <div className={s.bbCheckboxWrapper}>
                    <Form.Item name="responding" valuePropName="checked">
                      <Checkbox
                        id="responding"
                        name="responding"
                        _size="md"
                        className={s.bbCheckbox}
                        onChange={e => handleCheckboxChange(e.target.checked, "responding")}
                      />
                    </Form.Item>
                    <Label
                      htmlFor="responding"
                      className={s.bbCheckboxLabel}
                      text={t(`${step1TransBase}:responding`)}
                    />
                  </div>
                  <div className={s.bbCheckboxWrapper}>
                    <Form.Item name="insights" valuePropName="checked">
                      <Checkbox
                        id="insights"
                        name="insights"
                        _size="md"
                        className={s.bbCheckbox}
                        onChange={e => handleCheckboxChange(e.target.checked, "insights")}
                      />
                    </Form.Item>
                    <Label
                      htmlFor="insights"
                      className={s.bbCheckboxLabel}
                      text={t(`${step1TransBase}:insights`)}
                    />
                  </div>
                  <div className={s.bbCheckboxWrapper}>
                    <Form.Item name="other" valuePropName="checked">
                      <Checkbox
                        id="other"
                        name="other"
                        _size="md"
                        className={s.bbCheckbox}
                        onChange={e => handleCheckboxChange(e.target.checked, "other")}
                      />
                    </Form.Item>
                    <Label
                      htmlFor="other"
                      className={s.bbCheckboxLabel}
                      text={t(`${step1TransBase}:other`)}
                    />
                  </div>
                  {showOtherPurpose && (
                    <Form.Item
                      name="purpose_other"
                      rules={[
                        {
                          required: true,
                          validateTrigger: "onBlur",
                          message: (
                            <FormErrorMessage>
                              {t(`${step1TransBase}:otherPurposeRequired`)}
                            </FormErrorMessage>
                          ),
                        },
                        {
                          max: 100,
                          validateTrigger: "onBlur",
                          message: (
                            <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>
                          ),
                        },
                        { ...SanitizedInputValidation },
                      ]}
                    >
                      <InputWhiteBordered
                        _size="sm"
                        maxLength={100}
                        hideMaxLengthChip
                        placeholder={t(`${step1TransBase}:otherPurposePlaceholder`)}
                      />
                    </Form.Item>
                  )}
                </div>
                {showCheckboxesRequiredError && (
                  <FormErrorMessage>{t(`${step1TransBase}:checkboxesRequired`)}</FormErrorMessage>
                )}
              </div>
            </div>
          </div>
          <div className={s.bbAIWorkflowStep1Footer}>
            <Form.Item>
              <Button
                type="submit"
                _type="primary"
                disabled={postingWorkflowAIModel}
                className={s.bbAIWorkflowStep1OkBtn}
                data-cy="ai-tagging-workflow-step1-okBtn"
              >
                {t(`${step1TransBase}:okBtn`)}
                <ChevronRightIcon />
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
};

export default Step1;
